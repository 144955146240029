import { Icon } from '@tallink/components-lib'
import NativeAdapter from '@tallink/native-adapter'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import { SHOW_CHAT_TAB } from '../../../config/config'
import s from './BottomNavigation.module.scss'
import NavLinkWithSearch from './NavLinkWithSearch'

export default function BottomNavigation() {
  const { t } = useTranslation()
  const match = useRouteMatch('/hotel/:hotelCode/')

  return (
    <nav className={classNames(s['bottom-navigation'], { [s.native]: NativeAdapter.isNative() })}>
      <NavLinkWithSearch activeClassName={s.active} className={s.tab} to={`/hotel/${match?.params.hotelCode}/services`}>
        <Icon className={s.icon} color="white" type="fork-and-knife" />
        <span className={s.label}>{t('page.services.title')}</span>
      </NavLinkWithSearch>
      <NavLinkWithSearch
        activeClassName={s.active}
        className={s.tab}
        to={`/hotel/${match?.params.hotelCode}/special-offers`}
      >
        <Icon className={s.icon} color="white" type="discount-coupon" />
        <span className={s.label}>{t('page.specialOffers.title')}</span>
      </NavLinkWithSearch>
      {SHOW_CHAT_TAB && (
        <NavLinkWithSearch
          activeClassName={s.active}
          className={s.tab}
          to={`/hotel/${match?.params.hotelCode}/contact`}
        >
          <Icon className={s.icon} color="white" type="chat" />
          <span className={s.label}>{t('page.contact.title')}</span>
        </NavLinkWithSearch>
      )}
    </nav>
  )
}
