import { Spinner } from '@tallink/components-lib'
import React from 'react'

import Layout from '../components/layout/Layout'
import s from './LoadingPage.module.scss'

export default function LoadingPage() {
  return (
    <Layout headerVisible={false} pageTitle="" stickBackground>
      <div className={s.loader}>
        <Spinner className={s.spinner} />
      </div>
    </Layout>
  )
}
