import { createReducer } from '@reduxjs/toolkit'

import { clearOrderToken, receivedOrderToken } from '../actions/actions'

export const initialState = null

const orderToken = createReducer(initialState, {
  [receivedOrderToken]: (state, { payload }) => payload,
  [clearOrderToken]: () => initialState
})

export default orderToken
