import { Icon } from '@tallink/components-lib'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BREAKFAST_TYPE, DELIVERY_TYPE_ROOM, DELIVERY_TYPE_TABLE, SPECIAL_OFFERS_TYPE } from '../../common/constants'
import s from './ConfirmationPanel.module.scss'

export default function ConfirmationPanel({ orderInformation }) {
  const { t } = useTranslation()
  const { deliveryDateTime, deliveryType, destinationNumber, guestEmail, isPaid, salePointType } = orderInformation
  const specialOffersWithoutDestinationNumber = salePointType === SPECIAL_OFFERS_TYPE && !destinationNumber

  return (
    <div className={s['confirmation-panel']}>
      <Icon className={s.icon} color="light-turquoise" type="tick-in-circle-default" />
      <p className={s.title}>{t('confirmationPage.confirmed')}</p>
      <p className={s.information}>
        {isPaid ? t('confirmationPage.orderAcceptedAndPaid') : t('confirmationPage.orderAccepted')}
      </p>
      {destinationNumber && (
        <div className={s.information}>
          <p>{deliveryType === DELIVERY_TYPE_ROOM ? t('rightMenu.room') : t('confirmationPage.table')}:</p>
          <p className={s.value}>{destinationNumber}</p>
        </div>
      )}
      {!specialOffersWithoutDestinationNumber && deliveryType === DELIVERY_TYPE_ROOM && (
        <div className={s.information}>
          <p>{t('confirmationPage.estimatedDeliveryTime')}:</p>
          <p className={classNames(s.value, s['delivery-time'])}>{createDeliveryTimeLabel()}</p>
        </div>
      )}

      {specialOffersWithoutDestinationNumber && (
        <div className={s.information}>
          <p>{t('confirmationPage.roomDelivery.notLoggedIn')}</p>
        </div>
      )}

      {guestEmail && (
        <div className={s.information}>
          <p>{t('confirmationPage.confirmationSentTo')}</p>
          <p className={s.value}>{guestEmail}</p>
        </div>
      )}

      {deliveryType === DELIVERY_TYPE_TABLE && (
        <div className={s.information}>
          <p className={s.value}>{t('confirmationPage.switchTableWarning')}</p>
        </div>
      )}
    </div>
  )

  function createDeliveryTimeLabel() {
    if (salePointType === BREAKFAST_TYPE) return format(parseISO(deliveryDateTime), t('date.fullDateWithTime'))
    return t('deliveryTimeoutInMinutes.value') + t('confirmationPage.minute')
  }
}

ConfirmationPanel.propTypes = {
  orderInformation: PropTypes.shape({
    deliveryDateTime: PropTypes.string,
    deliveryTimeoutInMinutes: PropTypes.number,
    deliveryType: PropTypes.string,
    destinationNumber: PropTypes.string,
    guestEmail: PropTypes.string,
    isPaid: PropTypes.bool,
    salePointType: PropTypes.string
  })
}
