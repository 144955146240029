import { useScript } from '@tallink/more-react-hooks'
import { useEffect } from 'react'

export default function useSso({
  containerSelector,
  hideAuthButton,
  isMobile,
  language,
  mobileBackground = true,
  onCollapseOverlay = () => {},
  onError = () => {},
  onExpandOverlay = () => {},
  onGetUser = () => {},
  onRender = () => {},
  onSaveCookiesError = () => {},
  onSignIn = () => {},
  onSignOut = () => {},
  showSignedInMobile = false,
  token,
  url
}) {
  const protocolAndAuthority = url.split('/').slice(0, 3).join('/')

  const ssoSettings = {
    buttonAlwaysVisibleOnMobile: false,
    container: containerSelector,
    hideAuthButton,
    isMobile,
    language: getSsoLocaleFromLanguage(language),
    mobileBackground,
    onCollapseOverlay,
    onError,
    onExpandOverlay,
    onGetUser,
    onRender,
    onSaveCookiesError,
    onSignIn,
    onSignOut,
    pathToServer: protocolAndAuthority,
    showSignedInMobile
  }
  const ssoSettingsJson = JSON.stringify(ssoSettings)

  const status = useScript(url)

  useEffect(() => {
    if (status === 'ready' && document.querySelector(ssoSettings.container)) {
      if (typeof window.SSO?.render !== 'function') {
        // eslint-disable-next-line no-console
        const logError = window.TrackJS?.console.error || console.error
        logError('SSO.render() is not a function')
        return
      }
      window.SSO.render(ssoSettings)
      if (token) window.SSO?.login(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoSettingsJson, status, token])
}

export function getSsoLocaleFromLanguage(language) {
  const appLanguageToSsoLocale = {
    de: 'de-DE',
    en: 'en-US',
    et: 'et-EE',
    fi: 'fi-FI',
    ja: 'jp-US',
    ko: 'ko-US',
    lt: 'lt-LT',
    lv: 'lv-LV',
    ru: 'ru-RU',
    sv: 'sv-SE',
    zh: 'zn-CH'
  }
  return typeof language === 'string'
    ? appLanguageToSsoLocale?.[language.slice(0, 2)] ?? appLanguageToSsoLocale.en
    : appLanguageToSsoLocale.en
}
