import './sso-background-overrides.scss'

import { mediaQueries, useCookieConsent, useSso } from '@tallink/components-lib'
import { useQuery } from '@tallink/more-react-hooks'
import NativeAdapter from '@tallink/native-adapter'
import { format } from 'date-fns'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Redirect, Route, Switch, generatePath, useHistory } from 'react-router-dom'
import { useEffectOnce, useMedia } from 'react-use'

import {
  getUserProfile,
  handleSignIn,
  handleSignedOut,
  ssoClosed,
  ssoErrorOccurred,
  ssoOpened,
  ssoRendered
} from './actions/actions'
import { TIME_TO_LOGOUT } from './common/constants'
import { getBackgroundImageUrl } from './common/hotels'
import { DEFAULT_START_URL, removeQueryParams } from './common/router'
import { ROUTES } from './common/routes'
import AppDownloadBanner from './components/AppDownloadBanner/AppDownloadBanner'
import Aside from './components/Aside/Aside'
import { DEFAULT_LOCALE, getEnv, getEnvBasedConfig } from './config/config'
import useResetOrderRequest from './hooks/useResetOrderRequest'
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage'
import ContactPage from './pages/ContactPage/ContactPage'
import PaymentRejectedPage from './pages/PaymentRejectedPage/PaymentRejectedPage'
import ServicePage from './pages/ServicePage/ServicePage'
import ServicesPage from './pages/ServicesPage/ServicesPage'
import ShoppingPage from './pages/ShoppingPage/ShoppingPage'
import SpecialOffersPage from './pages/SpecialOffersPage/SpecialOffersPage'
import { isUserLoggedIn } from './selectors/selectors'

function App() {
  useResetOrderRequest()
  const dispatch = useDispatch()
  const userLoggedIn = useSelector(isUserLoggedIn)
  const nativeUser = NativeAdapter.getUser()
  const locale = useQuery('locale')
  const history = useHistory()
  const { location } = history
  const smUp = useMedia(mediaQueries.smUp)

  useSso({
    containerSelector: '.sso-container',
    language: i18n.language,
    url: getEnvBasedConfig().ssoUrl,
    onError: () => dispatch(ssoErrorOccurred()),
    onRender: () => dispatch(ssoRendered()),
    onGetUser: () => dispatch(getUserProfile()),
    onSignIn: (token, userId) => dispatch(handleSignIn({ token, userId, history })),
    onSignOut: () => dispatch(handleSignedOut({ history })),
    onExpandOverlay: () => dispatch(ssoOpened()),
    onCollapseOverlay: () => dispatch(ssoClosed()),
    hideAuthButton: true,
    isMobile: true,
    mobileBackground: false,
    token: nativeUser?.ssoToken
  })

  useCookieConsent({
    env: 'mirdic',
    hostEnv: getEnv(),
    language: i18n.language
  })

  const bookingPageMatch = useRouteMatch(ROUTES.HOTEL)
  const confirmationPageMatch = useRouteMatch(ROUTES.CONFIRMATION_PAGE)
  const paymentRejectedPageMatch = useRouteMatch(ROUTES.PAYMENT_REJECTED_PAGE)

  const hotelCode = bookingPageMatch?.params.hotelCode

  useEffectOnce(() => {
    NativeAdapter.sendMessageToNative('onload')
  })

  useEffectOnce(() => {
    const key = 'specialOffersPage'
    const searchParams = new URLSearchParams(history.location.search)

    if (searchParams.get(key)) {
      const specialOffersPageUrl = generatePath(ROUTES.SPECIAL_OFFERS_PAGE, { hotelCode })
      const queryParams = removeQueryParams(history.location.search, [key])
      history.push({
        pathname: specialOffersPageUrl,
        searchParams: queryParams
      })
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (userLoggedIn && smUp && format(new Date(), 'HH:mm') === TIME_TO_LOGOUT) {
        clearInterval(interval)
        return window.SSO?.signOut()
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [smUp, userLoggedIn])

  if (!locale) return <RedirectToSameUrlWithDefaultLocale />

  const asideVisible = !!hotelCode && !confirmationPageMatch && !paymentRejectedPageMatch

  return (
    <>
      <AppDownloadBanner />
      <div
        className="sso-container"
        style={{
          '--bg-image': `url(${getBackgroundImageUrl(hotelCode)})`
        }}
      />
      {asideVisible && <Aside hotelCode={hotelCode} />}
      <Switch>
        <Route component={ServicePage} path={ROUTES.SERVICE_PAGE} />
        <Route component={ServicesPage} path={ROUTES.SERVICES_PAGE} />
        <Route component={SpecialOffersPage} path={ROUTES.SPECIAL_OFFERS_PAGE} />
        <Route component={ContactPage} path={ROUTES.CONTACT_PAGE} />
        <Route component={ShoppingPage} path={ROUTES.SHOPPING_PAGE} />
        <Route component={ConfirmationPage} path={ROUTES.CONFIRMATION_PAGE} />
        <Route component={PaymentRejectedPage} path={ROUTES.PAYMENT_REJECTED_PAGE} />
        <Redirect from="*" to={DEFAULT_START_URL + location.search + location.hash} />
      </Switch>
    </>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App

function RedirectToSameUrlWithDefaultLocale() {
  const { location } = useHistory()
  const search = new URLSearchParams(location.search)
  search.set('locale', DEFAULT_LOCALE)
  const url = `${location.pathname}?${search.toString()}${location.hash}`

  return <Redirect to={url} />
}
