import { createReducer } from '@reduxjs/toolkit'
import produce from 'immer'
import _ from 'underscore'

import {
  addProduct,
  eraseProduct,
  receivedSalePoints,
  removeAllProducts,
  removeProduct,
  restoreProducts,
  updateSalePoint
} from '../actions/actions'
import { findSalePointByCode, isSalePointClosed } from '../common/sale-points'
import { getProducts, getSelectedProducts } from '../selectors/selectors'

const initialState = []

function restoreSelectedProducts(newSalePoints, selectedProducts) {
  return produce(newSalePoints, draftSalePoints => {
    selectedProducts.forEach(({ productId, count, quantity }) => {
      findAndUpdateProduct(draftSalePoints, productId, draftProduct => {
        draftProduct.count = count
        if (quantity >= 0) draftProduct.quantity = quantity
      })
    })
  })
}

const salePoints = createReducer(initialState, {
  [receivedSalePoints]: (currentSalePoints, { payload: newSalePoints }) => {
    const selectedProducts = getSelectedProducts({ salePoints: currentSalePoints })
    if (selectedProducts.length === 0) return newSalePoints

    return restoreSelectedProducts(newSalePoints, selectedProducts)
  },
  [updateSalePoint]: (currentSalePoints, { payload: newSalePoint }) => {
    const oldSalePoint = findSalePointByCode(currentSalePoints, newSalePoint.salePointCode)
    const updatedSalePoints = produce(currentSalePoints, draftSalePoints => {
      const index = currentSalePoints.indexOf(oldSalePoint)
      draftSalePoints[index] = newSalePoint
    })

    const selectedProducts = getSelectedProducts({ salePoints: currentSalePoints })
    if (selectedProducts.length === 0 || isSalePointClosed(oldSalePoint)) {
      return updatedSalePoints
    }

    return restoreSelectedProducts(updatedSalePoints, selectedProducts)
  },
  [addProduct]: (state, { payload }) => {
    findAndUpdateProduct(state, payload, draftProduct => {
      if (_.isUndefined(draftProduct.count)) {
        draftProduct.count = 0
      }

      draftProduct.count += 1
    })
  },
  [removeProduct]: (state, { payload }) => {
    findAndUpdateProduct(state, payload, draftProduct => {
      if (draftProduct.count > 0) {
        draftProduct.count -= 1
      }
    })
  },
  [removeAllProducts]: state => {
    getSelectedProducts({ salePoints: state }).forEach(draftProduct => {
      draftProduct.count = 0
    })
  },
  [eraseProduct]: (state, { payload }) => {
    findAndUpdateProduct(state, payload, draftProduct => {
      draftProduct.count = 0
    })
  },
  [restoreProducts]: (state, { payload: { orderedItems, salePointCode } }) => {
    const salePoint = findSalePointByCode(state, salePointCode)

    orderedItems.forEach(orderedItem => {
      const productToRestore = getProducts({ salePoints: [salePoint] }).find(
        product => product.isAvailable && product.name.title === orderedItem.nameEnglish
      )

      if (productToRestore) productToRestore.count = orderedItem.quantity
    })
  }
})

function findAndUpdateProduct(salePoints, productId, cb) {
  const product = getProducts({ salePoints }).find(product => product.productId === productId)

  if (!product) return

  cb(product)
}

export default salePoints
