import { createSelector } from '@reduxjs/toolkit'
import _ from 'underscore'

import { DELIVERY_TYPE_ROOM, SHOPPING_CART_WARNINGS } from '../common/constants'
import { isAlcoholSaleClosing, isSpecialOffersSalePoint } from '../common/sale-points'

export const isSidebarOpen = state => state.layout.sidebarOpen
export const getBackgroundImageUrl = state => state.layout.backgroundImageUrl

export const isErrorOccurred = state => !!state.apiError

export const isSsoOpen = ({ sso }) => sso.open
export const isSsoDisabled = ({ sso }) => !sso.rendered || sso.errorOccurred

export const isUserLoggedIn = ({ user }) => !!user.userId
export const getUser = ({ user }) => user
export const isUserProfileLoaded = ({ user }) => Boolean(user.firstName && user.lastName)
export const getStoredPaymentMethods = ({ user }) => user.storedPaymentMethods ?? []
export const getFirstBooking = ({ user }) => user.bookings?.[0]

export const getApiError = ({ apiError }) => apiError

export const getAllSalePoints = ({ salePoints }) => salePoints
export const getSalePoints = ({ salePoints }) => salePoints.filter(_.negate(isSpecialOffersSalePoint))

export const getSpecialOffersSalePoint = ({ salePoints }) => salePoints.find(isSpecialOffersSalePoint)

export const getSummary = ({ summary }) => summary

export const getProducts = ({ salePoints }) =>
  salePoints.flatMap(salePoint => salePoint.categories.flatMap(category => category.products))

export const getSpecialOffersProducts = createSelector(
  getSpecialOffersSalePoint,
  specialOffersSalePoint => specialOffersSalePoint?.categories[0].products
)

export const getSelectedProducts = createSelector(getProducts, products =>
  products.filter(product => product.count > 0)
)

export const hasSelectedProducts = createSelector(getSelectedProducts, products => !!products.length)

export const findFirstSalePointWithSelectedProduct = ({ salePoints }) =>
  salePoints.find(
    salePoint =>
      salePoint.categories.flatMap(category => category.products).filter(product => product.count > 0).length > 0
  )

export const getOrderInformation = ({ orderInformation }) => orderInformation

export const getOrderToken = ({ orderToken }) => orderToken

export const getOrderRequest = ({ orderRequest }) => orderRequest

export const getRoomNumberFromProfile = createSelector([getFirstBooking], booking => booking?.roomNumber)

export const isDeliveryDestinationNumberValid = createSelector(
  [findFirstSalePointWithSelectedProduct, getOrderRequest, getRoomNumberFromProfile],
  (salePoint, orderRequest, roomNumberFromProfile) => {
    if (!roomNumberFromProfile && isSpecialOffersSalePoint(salePoint)) return true

    const { deliveryDestinationNumber, deliveryType } = orderRequest
    return (
      !!deliveryDestinationNumber &&
      (deliveryType === DELIVERY_TYPE_ROOM
        ? deliveryDestinationNumber.length > 2
        : deliveryDestinationNumber.length > 0)
    )
  }
)

export const getShoppingCartWarnings = createSelector(
  [getSummary, getSelectedProducts, findFirstSalePointWithSelectedProduct],
  (summary, selectedProducts, salePoint) => {
    const hasProductWithZeroQuantity = summary.products.some(({ quantity }) => quantity === 0)
    const hasProductWithPriceChange = selectedProducts.some(selectedProduct =>
      summary.products.some(
        summaryProduct =>
          selectedProduct.productId === summaryProduct.productId && selectedProduct.price !== summaryProduct.price
      )
    )
    const hasProductWithAlcoholRestriction = salePoint
      ? isAlcoholSaleClosing(salePoint) && summary.hasAlcoholProducts
      : false

    const addIf = (condition, value) => (condition ? value : [])

    return []
      .concat(addIf(hasProductWithZeroQuantity, SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_ZERO_QUANTITY))
      .concat(addIf(hasProductWithPriceChange, SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_PRICE_CHANGE))
      .concat(addIf(hasProductWithAlcoholRestriction, SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_ALCOHOL_RESTRICTION))
  }
)
