import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'

import Icon from '../Icon/Icon'
import s from './Select.module.scss'

export default function Select({ id, options, onChange, value, className, disabled }) {
  return (
    <div className={classNames(s['select-wrapper'], className)}>
      <select className={s.select} disabled={disabled} id={id} onChange={e => onChange(e.target.value)} value={value}>
        {options.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
      <Icon className={s.icon} type="chevron-down" />
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  value: PropTypes.string
}
