import { Tab, Tabs } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BOOK_TAB_NAME, ORDER_TAB_NAME } from '../../common/constants'
import s from './ServiceTabs.module.scss'

export default function ServiceTabs({ activeTab, onClick }) {
  const { t } = useTranslation()
  const tabs = [ORDER_TAB_NAME, BOOK_TAB_NAME]

  return (
    <Tabs className={s['service-tabs']}>
      {tabs.map(name => (
        <Tab
          className={s.tab}
          key={name}
          onClick={() => onClick(name)}
          selected={activeTab === name}
          selectedClassName={s.active}
        >
          {t(`services.tabName.${name}`)}
        </Tab>
      ))}
    </Tabs>
  )
}

ServiceTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
