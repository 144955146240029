import React, { useEffect, useState } from 'react'

import Toast from '../../Toast/Toast'

export const GlobalToastActions = {
  show: () => {},
  hide: () => {}
}

const hiddenState = { visible: false }

export default function GlobalToast() {
  const [state, setState] = useState(hiddenState)

  const hideToast = () => setState(hiddenState)
  const hideAfter = handler => async () => {
    if (handler) await handler()
    hideToast()
  }
  const handleOpen = handler => async () => {
    if (handler) await handler()
  }

  useEffect(() => {
    GlobalToastActions.hide = hideToast
    GlobalToastActions.show = args => setState({ ...args, visible: true })
  })

  if (!state.visible) return null

  return (
    <Toast
      closeButtonText={state.closeButtonText}
      onClose={hideAfter(state.onClose)}
      onOpen={handleOpen(state.onOpen)}
      type={state.type}
    >
      {state.children}
    </Toast>
  )
}
