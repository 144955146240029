import { BrowserDetector, Icon } from '@tallink/components-lib'
import { useQuery } from '@tallink/more-react-hooks'
import NativeAdapter from '@tallink/native-adapter'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ReactComponent as AppIcon } from '../../images/icons/icon_appicon_banner.svg'
import s from './AppDownloadBanner.module.scss'

export default function AppDownloadBanner() {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const kiosk = useQuery('mode') === 'kiosk'
  const showBanner =
    !NativeAdapter.isNative() &&
    !kiosk &&
    BrowserDetector.getMobileOperatingSystem() === BrowserDetector.MOBILE_OS.ANDROID
  const [bannerVisible, setBannerVisibility] = useState(showBanner)

  const ANDROID_APP_LINK = `intent://hotelservices.tallink.com${pathname}/#Intent;scheme=https;package=com.tallink.mikiandroid;end`
  const openAndroidApp = () => window.location.replace(ANDROID_APP_LINK)

  if (!bannerVisible) return null

  return (
    <div className={s['app-download-banner']} id="app-download-banner">
      <button className={s['close-button']} onClick={() => setBannerVisibility(false)}>
        <Icon color="white" size="xxs" type="cross" />
      </button>
      <AppIcon className={s['app-icon']} />
      <div className={s.info} onClick={openAndroidApp} onKeyPress={() => {}} role="button" tabIndex="-1">
        <div className={s.title} dangerouslySetInnerHTML={{ __html: t('banner.title') }} />
        <div className={s.description}>{t('banner.description')}</div>
      </div>
    </div>
  )
}
