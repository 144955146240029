import { DELIVERY_TYPE_ROOM, DELIVERY_TYPE_TABLE, SPECIAL_OFFERS_TYPE } from './constants'

export const findSalePointByCode = (salePoints, code) => salePoints.find(salePoint => salePoint.salePointCode === code)

export const findSalePointById = (salePoints, id) => salePoints.find(salePoint => salePoint.salePointId === id)

export const isSalePointClosing = ({ minutesBeforeClosing }) =>
  minutesBeforeClosing.general <= 15 && minutesBeforeClosing.general !== 0

export const isAlcoholSaleClosing = ({ minutesBeforeClosing }) =>
  minutesBeforeClosing.alcoholic <= 15 && minutesBeforeClosing.alcoholic !== 0

export const isSpecialOffersSalePoint = salePoint => salePoint?.type === SPECIAL_OFFERS_TYPE

export const isSalePointUnavailable = ({ isAvailable }) => !isAvailable

export const isSalePointClosed = ({ minutesBeforeClosing }) => minutesBeforeClosing.general === 0

export const findProductById = (products, id) => products.find(({ productId }) => productId === id)

export const getDeliveryParams = (deliveryTypeFromUrlQuery, roomNumberFromProfile, salePointSupportsTableDelivery) => {
  const isRoom = value => value === DELIVERY_TYPE_ROOM
  const isTable = value => value === DELIVERY_TYPE_TABLE

  const createDeliveryParams = (deliveryType, deliveryDestination = '') => ({ deliveryType, deliveryDestination })

  if (isRoom(deliveryTypeFromUrlQuery)) return createDeliveryParams(DELIVERY_TYPE_ROOM, roomNumberFromProfile)
  if (isTable(deliveryTypeFromUrlQuery) && salePointSupportsTableDelivery) {
    return createDeliveryParams(DELIVERY_TYPE_TABLE)
  }

  return createDeliveryParams(DELIVERY_TYPE_ROOM)
}

export const convertWorkingScheduleToString = ({ name: { workingSchedule } }) =>
  workingSchedule.map(({ date, time }) => `${date}: ${time}`).join(', ')
