import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import { matchPath } from 'react-router'

import { DEFAULT_HOTEL_CODE } from '../common/constants'
import { ROUTES } from '../common/routes'
import { DEFAULT_LOCALE } from '../config/config'
import translations from './translations'

const languageDetector = new LanguageDetector()
languageDetector.addDetector({
  name: 'tallinkQueryStringDetector',
  lookup() {
    if (typeof window === 'undefined') return undefined
    return convertSearchStringToLocale(window.location.search)
  }
})

i18n
  .use(ICU)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['tallinkQueryStringDetector']
    },
    supportedLngs: getSupportedLocales(),
    resources: translations,
    fallbackLng: DEFAULT_LOCALE,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  })

export function convertSearchStringToLocale(search) {
  const searchParams = new URLSearchParams(search)
  const language = searchParams.get('locale') || DEFAULT_LOCALE
  if (!language) return undefined
  if (language.length === 5) return language.substring(0, 2)
  return language
}

export function getSupportedLocales() {
  const hotelCode = matchPath(window.location.pathname, ROUTES.HOTEL)?.params.hotelCode || DEFAULT_HOTEL_CODE
  return hotelCode === 'queen' ? ['en', 'et', 'fi', 'sv'] : ['en', 'et', 'fi']
}
