import NativeAdapter from '@tallink/native-adapter'
import i18n from 'i18next'
import { matchPath } from 'react-router'

import { removeAllProducts, toggleSidebar } from '../actions/actions'
import { GlobalModalActions } from '../components/layout/GlobalModal/GlobalModal'
import { DEFAULT_HOTEL_CODE } from './constants'
import { ROUTES } from './routes'

export const ROUTE_CHANGE_TIMEOUT = 100

export const DEFAULT_START_URL = `/hotel/${DEFAULT_HOTEL_CODE}/services`

export const ROUTE_CHANGE_CONFIRMATION_TYPES = {
  REMOVE_ALL_PRODUCTS: 'REMOVE_ALL_PRODUCTS',
  DEFAULT: 'DEFAULT'
}

export const IGNORE_NATIVE_HISTORY_KEY = 'ignoreHistoryOnNativeBackButtonClick'

const ROUTE_CHANGE_CONFIRMATION_HANDLERS = {
  CLOSE_SIDEBAR: (routerConfirmationCallback, dispatch) => {
    setTimeout(() => dispatch(toggleSidebar()), ROUTE_CHANGE_TIMEOUT)
    return routerConfirmationCallback(false)
  },
  REMOVE_ALL_PRODUCTS: (routerConfirmationCallback, dispatch) => {
    GlobalModalActions.show({
      children: i18n.t('modal.quitOrder.title'),
      cancelButtonText: i18n.t('modal.quitOrder.cancel'),
      confirmButtonText: i18n.t('modal.quitOrder.confirm'),
      onCancel: () => routerConfirmationCallback(false),
      onConfirm: () => {
        dispatch(removeAllProducts())
        routerConfirmationCallback(true)
      }
    })
  },
  DEFAULT: callback => callback(true)
}

const ALLOWED_LOCATION_CHANGES_FROM_PAGE = {
  SERVICE_PAGE: location => {
    const leavingToShoppingPage = matchPath(location.pathname, ROUTES.SHOPPING_PAGE)
    const returningBackToServicePage = matchPath(location.pathname, ROUTES.SERVICE_PAGE)

    return leavingToShoppingPage || returningBackToServicePage
  },

  SPECIAL_OFFERS_PAGE: location => {
    const leavingToShoppingPage = matchPath(location.pathname, ROUTES.SHOPPING_PAGE)
    const returningBackToSpecialOffersPage = matchPath(location.pathname, ROUTES.SPECIAL_OFFERS_PAGE)

    return leavingToShoppingPage || returningBackToSpecialOffersPage
  }
}

export const getLocationChangeGuard = (message, page) => location => {
  const locationStateChanged = Object.keys(location?.state || {}).length > 0
  const pageAllowsLocationChange = ALLOWED_LOCATION_CHANGES_FROM_PAGE[page](location)

  return locationStateChanged || pageAllowsLocationChange ? true : message
}

export const routeChangeConfirmation = (dispatch, getState) => (
  message = ROUTE_CHANGE_CONFIRMATION_TYPES.DEFAULT,
  routerConfirmationCallback
) => {
  if (getState().layout.sidebarOpen) {
    return ROUTE_CHANGE_CONFIRMATION_HANDLERS.CLOSE_SIDEBAR(routerConfirmationCallback, dispatch)
  }

  return ROUTE_CHANGE_CONFIRMATION_HANDLERS[message](routerConfirmationCallback, dispatch)
}

export const addQueryParams = (search, params) => {
  const queryParams = new URLSearchParams(search)
  Object.entries(params).forEach(([param, value]) => {
    queryParams.set(param, value)
  })

  return queryParams
}

export const removeQueryParams = (search, params) => {
  const queryParams = new URLSearchParams(search)
  params.forEach(param => queryParams.delete(param))

  return queryParams
}

export const addIgnoreNativeHistory = (history, location) => ignoreNativeHistory(history, location, true)

export const removeIgnoreNativeHistory = (history, location) => ignoreNativeHistory(history, location, false)

const ignoreNativeHistory = (history, location, ignoreHistory) => {
  const hasFlag = new URLSearchParams(location.search).get(IGNORE_NATIVE_HISTORY_KEY) !== null

  if ((hasFlag && ignoreHistory) || (!hasFlag && !ignoreHistory) || !NativeAdapter.isAndroidNative()) return

  const queryParams = ignoreHistory
    ? addQueryParams(location.search, { [IGNORE_NATIVE_HISTORY_KEY]: '' })
    : removeQueryParams(location.search, [IGNORE_NATIVE_HISTORY_KEY])

  history.replace({
    pathname: location.pathname,
    search: queryParams.toString()
  })
}
