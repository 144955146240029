import * as PropTypes from 'prop-types'
import React from 'react'

import s from './Overlay.module.scss'

export default function Overlay({ children }) {
  return <div className={s.overlay}>{children}</div>
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired
}
