import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Icon from '../Icon/Icon'
import s from './RadioSwitcherAsBlocks.module.scss'

export default function RadioSwitcherAsBlocks({
  className,
  disabled,
  inColumn,
  value,
  name,
  onChange,
  optional,
  options
}) {
  const [selectedValue, setSelectedValue] = useState(value)

  const validateAndProcess = (event, nextValue) => {
    event.persist()
    setSelectedValue(prevValue => {
      if (optional && prevValue === nextValue) {
        // eslint-disable-next-line no-param-reassign
        event.target.checked = false
        return ''
      }

      return nextValue
    })
  }

  useEffect(() => {
    if (value !== selectedValue) onChange(selectedValue)
  }, [onChange, selectedValue, value])

  return (
    <div
      className={classNames(
        s['radio-switcher-as-blocks'],
        { [s['in-row']]: !inColumn, [s['in-column']]: inColumn },
        className
      )}
    >
      {options.map(option => (
        <label className={s.label} htmlFor={name + option.value} key={option.value}>
          <input
            className={s['radio-input']}
            defaultChecked={option.value === value}
            disabled={disabled}
            id={name + option.value}
            name={name}
            onClick={event => validateAndProcess(event, option.value)}
            type="radio"
            value={option.value}
          />
          <span className={s.text}>
            {option.value === selectedValue && <Icon type="check" />}
            {option.text}
          </span>
        </label>
      ))}
    </div>
  )
}

RadioSwitcherAsBlocks.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inColumn: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string
}
