import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import MorePropTypes from '../../common/MorePropTypes'
import { scrollToEndOfElement } from '../../common/Scroll'
import Icon from '../Icon/Icon'
import Spinner from '../Spinner/Spinner'
import s from './Accordion.module.scss'

const align = {
  center: s.center,
  left: s.left
}

const defaultIconTypes = {
  open: 'plus',
  close: 'minus'
}

export default function Accordion({
  children,
  className,
  iconSize = 's',
  iconTypes = defaultIconTypes,
  initiallyOpen = false,
  onClick,
  paddingSize,
  scrollToBottomOnOpen,
  titleAlign = 'center',
  titleContent,
  loading
}) {
  const [opened, setOpened] = useState(initiallyOpen)
  const ref = useRef()

  function handleOnClick() {
    if (loading) return
    if (onClick) onClick(opened)
    setOpened(!opened)
  }

  useEffect(() => {
    setOpened(initiallyOpen)
  }, [setOpened, initiallyOpen])

  useUpdateEffect(() => {
    if (opened && scrollToBottomOnOpen) scrollToEndOfElement(ref.current)
  }, [opened])

  return (
    <div className={classNames(s.accordion, className)} ref={ref}>
      <button aria-expanded={opened} className={s.header} onClick={handleOnClick} type="button">
        <div className={classNames(s.text, align[titleAlign])}>{titleContent}</div>
        {loading ? <Spinner /> : <Icon size={iconSize} type={opened ? iconTypes.close : iconTypes.open} />}
      </button>
      {opened && (
        <div className={classNames(s.body, { [s['padding-none']]: paddingSize === 'none' })} role="region">
          {children}
        </div>
      )}
    </div>
  )
}

Accordion.propTypes = {
  analyticParams: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  className: MorePropTypes.className,
  iconSize: PropTypes.string,
  iconTypes: PropTypes.shape({ open: PropTypes.string, close: PropTypes.string }),
  initiallyOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  paddingSize: PropTypes.string,
  scrollToBottomOnOpen: PropTypes.bool,
  titleAlign: PropTypes.oneOf(['center', 'left']),
  titleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}
