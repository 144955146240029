import { DELIVERY_TYPE_TABLE, PAY_NOW } from './constants'

export default function borderGradient({
  userLoggedIn,
  deliveryDestinationNumberValid,
  watchPayNowOrLater,
  contactInfoValid,
  payLaterAvailable,
  storedPaymentMethods,
  deliveryType
}) {
  const steps = [
    !userLoggedIn ? { step: 'signIn', status: 'todo' } : undefined,
    { step: 'deliveryDestinationNumber', status: deliveryDestinationNumberValid ? 'done' : 'todo' },
    watchPayNowOrLater === PAY_NOW ? { step: 'contactInfo', status: contactInfoValid ? 'done' : 'todo' } : undefined,
    payLaterAvailable && deliveryType === DELIVERY_TYPE_TABLE
      ? { step: 'payNowOrLater', status: watchPayNowOrLater ? 'done' : 'todo' }
      : undefined,
    { step: 'comments', status: 'todo' },
    watchPayNowOrLater === PAY_NOW && storedPaymentMethods.length > 0
      ? { step: 'paymentMethod', status: 'done' }
      : undefined
  ].filter(Boolean)

  const getStepStatus = stepName => steps.find(({ step }) => step === stepName)?.status

  const getBorderGradient = stepName => {
    const stepIndex = steps.findIndex(({ step }) => step === stepName)
    const isLastStep = stepIndex === steps.length - 1

    if (isLastStep) return ''

    return [steps[stepIndex]?.status || 'todo', steps[stepIndex + 1]?.status || 'todo'].join('-')
  }

  return { getBorderGradient, getStepStatus }
}
