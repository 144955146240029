import { MoreUrls } from '@tallink/components-lib'

export const DEFAULT_LOCALE = 'en'

export const LOCALE_TO_LOCALE_WITH_COUNTRY = {
  en: 'en-GB',
  et: 'et-EE',
  fi: 'fi-FI',
  ru: 'ru-RU'
}

export const SHOW_CHAT_TAB = false

const ENV_TO_EXTERNAL_SERVICES = {
  local: {
    mirdicUrl: '',
    paymentServiceUrl: 'https://dev-payment-service-public.fleet.zone',
    ssoUrl: 'https://dev-sso.fleet.zone/component/sso.js'
  },
  dev: {
    mirdicUrl: '',
    paymentServiceUrl: 'https://dev-payment-service-public.fleet.zone',
    ssoUrl: 'https://dev-sso.fleet.zone/component/sso.js'
  },
  test: {
    mirdicUrl: '',
    paymentServiceUrl: 'https://test-payment-service-public.fleet.zone',
    ssoUrl: 'https://test-sso.fleet.zone/component/sso.min.js'
  },
  prod: {
    mirdicUrl: '',
    paymentServiceUrl: 'https://payment.tallink.com',
    ssoUrl: 'https://sso.tallink.com/component/sso.min.js'
  }
}

const ENV_TO_SUPPORTED_HOSTNAMES = {
  local: ['localhost', 'bs-local.com', 'host.docker.internal'],
  dev: ['dev-mirdic.fleet.zone', 'ci.fleet.zone'],
  test: ['test-mirdic.fleet.zone'],
  prod: ['hotelservices.tallink.com']
}

export function getEnv(hostname) {
  const hostnameOrDefault = hostname || window.location.hostname
  if (isProdEnv(hostnameOrDefault)) return 'prod'
  if (isTestEnv(hostnameOrDefault)) return 'test'
  if (isDevEnv(hostnameOrDefault)) return 'dev'
  if (isLocalEnv(hostnameOrDefault)) return 'local'
  throw new Error(`Not able to initialize application because hostname ${hostname} is not supported.`)
}

export function getEnvBasedConfig(hostname) {
  const env = getEnv(hostname)
  return ENV_TO_EXTERNAL_SERVICES[env]
}

function isTestEnv(hostname) {
  return ENV_TO_SUPPORTED_HOSTNAMES.test.includes(hostname)
}

function isDevEnv(hostname) {
  return ENV_TO_SUPPORTED_HOSTNAMES.dev.includes(hostname)
}

function isLocalEnv(hostname) {
  return ENV_TO_SUPPORTED_HOSTNAMES.local.includes(hostname) || MoreUrls.isIpAddress(hostname)
}

function isProdEnv(hostname) {
  return ENV_TO_SUPPORTED_HOSTNAMES.prod.includes(hostname)
}
