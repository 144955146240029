import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { initConfirmationPage } from '../actions/actions'
import { getOrderInformation } from '../selectors/selectors'

export default function useConfirmationPageInit() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { orderToken } = useParams()
  const orderInformation = useSelector(getOrderInformation)
  const [initializing, setInitializing] = useState(false)

  useEffect(() => {
    if (!orderInformation) {
      if (!initializing) dispatch(initConfirmationPage(orderToken))
      setInitializing(true)
    } else {
      setInitializing(false)
    }
  }, [orderToken, dispatch, history, initializing, orderInformation])

  return { loading: initializing || !orderInformation, orderInformation }
}
