import React, { useEffect, useState } from 'react'

import Modal from '../../Modal/Modal'

export const GlobalModalActions = {
  show: () => {},
  hide: () => {}
}

const hiddenState = { visible: false }

export default function GlobalModal() {
  const [state, setState] = useState(hiddenState)

  const hideModal = () => setState(hiddenState)
  const hideAfter = handler => () => {
    if (handler) handler()
    hideModal()
  }

  useEffect(() => {
    GlobalModalActions.hide = hideModal
    GlobalModalActions.show = args => setState({ ...args, visible: true })
  })

  if (!state.visible) return null

  return (
    <Modal
      asView={state.asView}
      cancelButtonText={state.cancelButtonText}
      confirmButtonText={state.confirmButtonText}
      onCancel={hideAfter(state.onCancel)}
      onConfirm={hideAfter(state.onConfirm)}
    >
      {state.children}
    </Modal>
  )
}
