import { DEFAULT_HOTEL_CODE } from './constants'

/* eslint-disable global-require */
export function getBackgroundImageUrl(hotelCode = DEFAULT_HOTEL_CODE) {
  switch (hotelCode) {
    case 'city':
      return require('../images/bg-hotel-city.jpg')
    case 'spa':
      return require('../images/bg-hotel-spa.jpg')
    default:
      return require('../images/bg-hotel-city.jpg')
  }
}
