import { ButtonEmpty } from '@tallink/components-lib'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useEffectOnce } from 'react-use'

import s from './Toast.module.scss'

const typeToClassName = {
  primary: s.primary,
  warning: s.warning
}

export default function Toast({ children, type = 'primary', closeButtonText, onClose, onOpen = () => {} }) {
  useEffectOnce(() => {
    onOpen()
  })

  return (
    <div className={classNames(s.toast, typeToClassName[type])}>
      <div>{children}</div>
      {closeButtonText && (
        <ButtonEmpty className={s.close} onClick={onClose} type="primary">
          {closeButtonText}
        </ButtonEmpty>
      )}
    </div>
  )
}

Toast.propTypes = {
  children: PropTypes.string.isRequired,
  closeButtonText: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(typeToClassName))
}
