import * as PropTypes from 'prop-types'
import React from 'react'

import s from './PageHeaderDescription.module.scss'

export default function PageHeaderDescription({ title, description }) {
  return (
    <div className={s['page-header-description']}>
      <p className={s.title}>{title}</p>
      <p className={s.description}>{description}</p>
    </div>
  )
}

PageHeaderDescription.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
