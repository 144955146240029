import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { initPaymentRejectedPage } from '../actions/actions'
import { getAllSalePoints, getOrderInformation } from '../selectors/selectors'

export default function usePaymentRejectedPageInit() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { orderToken } = useParams()
  const orderInformation = useSelector(getOrderInformation)
  const [initializing, setInitializing] = useState(false)
  const salePoints = useSelector(getAllSalePoints)
  const loadedSalePoints = salePoints.length > 0

  useEffect(() => {
    if (!orderInformation || !loadedSalePoints) {
      if (!initializing) dispatch(initPaymentRejectedPage(history, orderToken))
      setInitializing(true)
    } else {
      setInitializing(false)
    }
  }, [dispatch, history, initializing, orderInformation, orderToken, loadedSalePoints])

  return { loading: initializing || !orderInformation, hotelCode: orderInformation?.hotelCode }
}
