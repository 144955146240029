import { ButtonIcon, Icon, PlusMinus } from '@tallink/components-lib'
import classNames from 'classnames'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router'

import { addProduct, eraseProduct, removeProduct } from '../../actions/actions'
import { ORDER_TAB_NAME } from '../../common/constants'
import { ROUTES } from '../../common/routes'
import { findProductById, findSalePointById, isSpecialOffersSalePoint } from '../../common/sale-points'
import { formattedPrice } from '../../common/strings'
import { getAllSalePoints, getProducts, getSummary } from '../../selectors/selectors'
import { GlobalModalActions } from '../layout/GlobalModal/GlobalModal'
import s from './OrderSummary.module.scss'

export default function OrderSummary() {
  const { t } = useTranslation()
  const history = useHistory()
  const products = useSelector(getProducts)
  const summary = useSelector(getSummary)
  const salePoints = useSelector(getAllSalePoints)
  const { hotelCode } = useParams()
  const salePoint = findSalePointById(salePoints, summary?.lastRequestPayload?.salePointId)

  const pathToSalePoint = () =>
    isSpecialOffersSalePoint({ type: salePoint?.type })
      ? generatePath(ROUTES.SPECIAL_OFFERS_PAGE, {
          hotelCode
        })
      : generatePath(ROUTES.SERVICE_PAGE, {
          hotelCode,
          salePointCode: salePoint?.salePointCode,
          tabName: ORDER_TAB_NAME
        })

  return (
    <div className={s['order-summary']}>
      <div className={s['title-container']}>
        <p className={s.title}>{t('shoppingPage.orderSummary')}</p>
        <ButtonIcon
          className={s['close-button']}
          color="light-slate-gray"
          iconSize="s"
          iconType="cross"
          onClick={() => history.push(pathToSalePoint() + history.location.search)}
        />
      </div>
      <div className={s.menu}>
        <div className={s['menu-header']}>
          <p className={s['menu-title']}>{t('shoppingPage.menuItem')}:</p>
          <p className={s['menu-title']}>{t('shoppingPage.amount')}:</p>
          <p className={s['menu-title']}>{t('shoppingPage.price')}:</p>
        </div>
        {summary.products.map(({ quantity, totalPrice, productId, price }) => {
          const product = findProductById(products, productId)
          const summaryProduct = findProductById(summary.products, productId)
          const priceChanged = price !== product.price && summaryProduct.productId === productId

          return (
            <div className={s['product-wrapper']} key={productId.toString()}>
              <div className={classNames(s.product, { [s['unavailable-product']]: !quantity })}>
                <p className={classNames(s['product-name'], { [s['unavailable-product-name']]: !quantity })}>
                  {product?.name.title}
                </p>
                {quantity ? (
                  <ChangeQuantity
                    product={product}
                    productId={productId}
                    products={products}
                    quantity={quantity}
                    totalPrice={totalPrice}
                  />
                ) : (
                  <RemoveProduct productId={productId} />
                )}
              </div>
              {priceChanged && <PriceChangedWarning />}
            </div>
          )
        })}
      </div>
      {summary.deliveryFee && (
        <div className={s['delivery-wrapper']}>
          <div className={s.delivery}>
            <p>{t('shoppingPage.deliveryFee.title')}</p>
            <div className={s['delivery-fee-value']}>{formattedPrice(summary.deliveryFee.value, i18n.language)}</div>
          </div>
          <div className={s.notice}>
            <Icon className={s['notice-icon']} color="viking" type="exclamation-circle-fill" />
            <p>
              {t(`shoppingPage.deliveryFee.threshold`, {
                threshold: formattedPrice(summary.deliveryFee.threshold, i18n.language)
              })}
            </p>
          </div>
        </div>
      )}
      {!!summary.clubOneDiscount && (
        <div className={s.discount}>
          <p>{t('shoppingPage.clubOneDiscount')}</p>
          <p>-{formattedPrice(summary.clubOneDiscount, i18n.language)}</p>
        </div>
      )}
      <div className={s['total-price']}>
        <p>{t('price.total')}</p>
        <p>{formattedPrice(summary.totalPrice, i18n.language)}</p>
      </div>
    </div>
  )
}

function ChangeQuantity({ quantity, productId, product, totalPrice, products }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onDecreaseClick = (productId, count) => {
    if (count === 1) {
      GlobalModalActions.show({
        children: t('modal.deleteItem.title', { item: findProductById(products, productId).name.title }),
        cancelButtonText: t('modal.deleteItem.cancel'),
        confirmButtonText: t('modal.deleteItem.remove'),
        onConfirm: () => {
          dispatch(removeProduct(productId))
        }
      })
    } else {
      dispatch(removeProduct(productId))
    }
  }

  return (
    <>
      <PlusMinus
        maxValue={99}
        onDecreaseClick={() => onDecreaseClick(productId, product?.count)}
        onIncreaseClick={() => dispatch(addProduct(productId))}
        value={quantity}
      />
      <p className={s.price}>{formattedPrice(totalPrice, i18n.language)}</p>
    </>
  )
}

ChangeQuantity.propTypes = {
  product: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  totalPrice: PropTypes.number
}

function RemoveProduct({ productId }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <>
      <ButtonIcon
        className={s['remove-button']}
        color="light-slate-gray"
        iconSize="xs"
        iconType="cross"
        onClick={() => dispatch(eraseProduct(productId))}
      />
      <div className={s.warning}>
        <Icon className={s['warning-icon']} type="exclamation-triangle-fill" />
        <p>{t('shoppingPage.itemUnavailable')}</p>
      </div>
    </>
  )
}

RemoveProduct.propTypes = {
  productId: PropTypes.number.isRequired
}

function PriceChangedWarning() {
  const { t } = useTranslation()

  return (
    <div className={s.warning}>
      <Icon className={s['notice-icon']} color="viking" type="exclamation-circle-fill" />
      <p>{t('shoppingPage.warning.priceChanged')}</p>
    </div>
  )
}
