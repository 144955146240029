import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './Text.module.scss'

export default function Text({ align, children, className, style, weight = 'regular' }) {
  return (
    <div
      className={classNames(s.text, className, {
        [s.left]: align === 'left',
        [s.center]: align === 'center',
        [s.right]: align === 'right',
        [s.regular]: weight === 'regular',
        [s.medium]: weight === 'medium',
        [s.bold]: weight === 'bold'
      })}
      style={style}
    >
      {children}
    </div>
  )
}

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: MorePropTypes.className,
  style: PropTypes.object,
  weight: PropTypes.oneOf(['regular', 'medium', 'bold'])
}
