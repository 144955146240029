import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'

import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import Icon from '../../Icon/Icon'
import s from './FieldTextControlLayout.module.scss'

export default function FieldTextControlLayout({
  className,
  children,
  errorMessage,
  onClearClick,
  invalid,
  showValidMark,
  size
}) {
  return (
    <div className={classNames(s['field-text-control-layout'], className)}>
      <div className={classNames(s.field)}>
        {children}
        {invalid && <ClearFieldButton onClearClick={onClearClick} size={size} />}
        {showValidMark && <Icon className={s['field-valid']} size="m" type="check" />}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
}

function ClearFieldButton({ onClearClick, size }) {
  return (
    <div className={classNames(s.icons, size === 's' ? s.xxs : s.xs)}>
      <button className={s.button} onClick={onClearClick} type="button">
        <Icon size={size === 's' ? 'xxs' : 'xs'} type="cross" />
      </button>
    </div>
  )
}

ClearFieldButton.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  size: PropTypes.string
}

FieldTextControlLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool,
  onClearClick: PropTypes.func.isRequired,
  showValidMark: PropTypes.bool,
  size: PropTypes.string
}
