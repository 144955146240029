import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { removeQueryParams } from '../../common/router'

export default function LinkWithSearch({ children, to }) {
  const search = removeQueryParams(useLocation().search, ['ignoreHistoryOnNativeBackButtonClick'])

  return <Link to={{ pathname: to, search: search.toString() }}>{children}</Link>
}

LinkWithSearch.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}
