import moment from 'moment'

let explainData = () => {}
let spec = { nilable: () => {}, or: () => {} }
let valid = () => {}

if (__DEV__ || __TEST__) {
  // eslint-disable-next-line global-require
  const jsSpec = require('js.spec')
  explainData = jsSpec.explainData
  spec = jsSpec.spec
  valid = jsSpec.valid
}

function isNumber(data) {
  return typeof data === 'number' && isFinite(data)
}

function isBoolean(data) {
  return typeof data === 'boolean'
}

function isString(data) {
  return typeof data === 'string'
}

function isMoment(data) {
  return moment.isMoment(data)
}

function isDate(data) {
  return String(data).match(/^\d{4}-[01]\d-[0-3]\d?$/)
}

function isTime(data) {
  return String(data).match(/^[0-5]\d(:[0-5]\d)?$/)
}

function isDateTime(data) {
  return String(data).match(/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?$/)
}

function isDateTimeWithOffset(data) {
  return String(data).match(/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?[+-][0-5]\d:[0-5]\d$/)
}

export const specs = {
  bool: isBoolean,
  date: isDate,
  time: isTime,
  moment: isMoment,
  number: isNumber,
  numberOrNull: spec.nilable('maybe number', isNumber),
  dateTime: isDateTime,
  dateTimeOrNull: spec.nilable('maybe date time string', isDateTime),
  string: isString,
  dateTimeOrMoment: spec.or('date time string or moment', {
    moment: isMoment,
    dateTime: isDateTime
  }),
  dateOrDateTime: spec.or('date as string or date time as string', {
    date: isDate,
    dateTime: isDateTime
  }),
  dateTimeWithOffset: isDateTimeWithOffset
}

/* eslint no-console: 0 */
export function checkArgument(s, data) {
  if ((__DEV__ || __TEST__) && !valid(s, data)) {
    const explained = explainData(s, data)
    console.warn("Argument doesn't satisfy the specs: ", explained)
  }
  return data
}

export function checkState(condition, message) {
  if ((__DEV__ || __TEST__) && !condition) {
    console.warn('Invalid state:', message)
  }
  return condition
}
