import { createReducer } from '@reduxjs/toolkit'

import { ssoClosed, ssoErrorOccurred, ssoOpened, ssoRendered } from '../actions/actions'

const initialState = { rendered: false, errorOccurred: false, open: false }

const sso = createReducer(initialState, {
  [ssoRendered]: state => {
    state.errorOccurred = false
    state.rendered = true
  },
  [ssoErrorOccurred]: state => {
    state.errorOccurred = true
  },
  [ssoOpened]: state => {
    state.open = true
  },
  [ssoClosed]: state => {
    state.open = false
  }
})

export default sso
