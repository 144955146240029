import { createReducer } from '@reduxjs/toolkit'

import { setBackgroundImageUrl, toggleSidebar } from '../actions/actions'

const initialState = { backgroundImageUrl: '', sidebarOpen: false }

const layout = createReducer(initialState, {
  [toggleSidebar]: state => {
    state.sidebarOpen = !state.sidebarOpen
  },
  [setBackgroundImageUrl]: (state, { payload }) => {
    state.backgroundImageUrl = payload
  }
})

export default layout
