import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export default function NavLinkWithSearch({ activeClassName, disabled, to, children, className }) {
  const { search } = useLocation()

  const navLinkToProp = { pathname: to, search }

  return (
    <NavLink
      activeClassName={activeClassName}
      component={React.forwardRef((props, ref) => (
        <button
          className={classNames(className, props.className)}
          disabled={disabled}
          // eslint-disable-next-line react/prop-types
          onClick={() => props.navigate()}
          ref={ref}
        >
          {children}
        </button>
      ))}
      isActive={(_, location) => location.pathname === to}
      to={navLinkToProp}
    />
  )
}

NavLinkWithSearch.propTypes = {
  activeClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.string.isRequired
}
