import { createReducer } from '@reduxjs/toolkit'

import { clearApiError, postOrderRequest, setApiError } from '../actions/actions'

export const initialState = null

const apiError = createReducer(initialState, {
  [setApiError]: (state, { payload }) => payload,
  [clearApiError]: () => initialState,
  [postOrderRequest]: () => initialState
})

export default apiError
