import { Icon } from '@tallink/components-lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { ROUTES } from '../../common/routes'
import ButtonWithRouter from '../../components/common/ButtonWithRouter'
import Layout from '../../components/layout/Layout'
import PaymentFailed from '../../components/PaymentFailed/PaymentFailed'
import usePaymentRejectedPageInit from '../../hooks/usePaymentRejectedPageInit'
import { getBackgroundImageUrl } from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './PaymentRejectedPage.module.scss'

export default function PaymentRejectedPage() {
  const { t } = useTranslation()

  const { loading, hotelCode } = usePaymentRejectedPageInit()
  const backgroundImageUrl = useSelector(getBackgroundImageUrl)

  if (loading) return <LoadingPage />

  const pathToShoppingPage = generatePath(ROUTES.SHOPPING_PAGE, { hotelCode })

  return (
    <Layout
      backgroundImageUrl={backgroundImageUrl}
      headerVisible={false}
      navigationVisible={false}
      pageTitle={t('paymentRejectedPage.title')}
    >
      <div className={s['payment-rejected-page']}>
        <div className={s.close}>
          <ButtonWithRouter className={s['close-button']} href={pathToShoppingPage}>
            <Icon color="light-slate-gray" size="s" type="cross" />
          </ButtonWithRouter>
        </div>
        <PaymentFailed />
        <div className={s['payment-rejected-button']}>
          <ButtonWithRouter fullWidth href={pathToShoppingPage}>
            {t('paymentRejectedPage.button')}
          </ButtonWithRouter>
        </div>
      </div>
    </Layout>
  )
}
