/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import moment from 'moment'
import _ from 'underscore'

import { isRoundTrip } from './VoyageType'

export const SHIP_CODES = [
  'EUROPA',
  'GALAXY',
  'ISABELLE',
  'MEGASTAR',
  'MYSTAR',
  'PRINCESS',
  'QUEEN',
  'ROMANTIKA',
  'SERENADE',
  'SYMPHONY',
  'VICTORIA',
  'SEAWIND'
]

export const SHIP_NAMES = [
  'Silja Europa',
  'Galaxy',
  'Isabelle',
  'Megastar',
  'MyStar',
  'Baltic Princess',
  'Baltic Queen',
  'Romantika',
  'Silja Serenade',
  'Silja Symphony',
  'Victoria I',
  'Sea Wind'
]

const SHIP_CODE_TO_NAME = {
  EUROPA: 'Silja Europa',
  GALAXY: 'Galaxy',
  ISABELLE: 'Isabelle',
  MEGASTAR: 'Megastar',
  MYSTAR: 'MyStar',
  PRINCESS: 'Baltic Princess',
  QUEEN: 'Baltic Queen',
  ROMANTIKA: 'Romantika',
  SEAWIND: 'Sea Wind',
  SERENADE: 'Silja Serenade',
  SYMPHONY: 'Silja Symphony',
  VICTORIA: 'Victoria I',
  NONE: ''
}

const SHIP_CODE_TO_WIFI_NAME = {
  EUROPA: 'Europa',
  GALAXY: 'Galaxy',
  ISABELLE: 'Isabelle',
  MEGASTAR: 'Megastar',
  MYSTAR: 'MyStar',
  PRINCESS: 'Baltic Princess',
  QUEEN: 'Queen',
  ROMANTIKA: 'Romantika',
  SEAWIND: 'Seawind',
  SERENADE: 'Serenade',
  SYMPHONY: 'Symphony',
  VICTORIA: 'Victoria'
}

const PREMIUM_WIFI_TRAVEL_CLASSES_BY_SHIP = {
  EUROPA: ['EXEC', 'LUX'],
  GALAXY: ['EXEC', 'SUIT', 'LUX'],
  ISABELLE: [],
  MEGASTAR: ['DFC'],
  MYSTAR: ['DFC', 'DPC', 'SUIT', 'AC'],
  PRINCESS: ['EXEC', 'SUIT', 'LUX'],
  QUEEN: ['EXEC', 'LUX', 'SUIT'],
  ROMANTIKA: ['SUIT', 'LUX'],
  SEAWIND: [],
  SERENADE: ['COMB', 'COMM', 'JS', 'EXEC'],
  SYMPHONY: ['COMB', 'COMM', 'JS', 'EXEC'],
  VICTORIA: ['SUIT', 'LUX']
}

export function isBalticPrincess(code) {
  return getShipNameByCode(code) === 'Baltic Princess'
}

export function isGalaxyShip(code) {
  return getShipNameByCode(code) === 'Galaxy'
}

export function isMegastarShip(code) {
  return getShipNameByCode(code) === 'Megastar'
}

export function isMyStarShip(code) {
  return getShipNameByCode(code) === 'MyStar'
}

export function isVictoriaShip(code) {
  return getShipNameByCode(code) === 'Victoria I'
}

export function isEuropaShip(code) {
  return getShipNameByCode(code) === 'Silja Europa'
}

export function isSymphonyShip(code) {
  return getShipNameByCode(code) === 'Silja Symphony'
}

export function isSerenadeShip(code) {
  return getShipNameByCode(code) === 'Silja Serenade'
}

export function isBalticPrincessShip(code) {
  return getShipNameByCode(code) === 'Baltic Princess'
}

export function isTravelClassWithPremiumWifi(shipCode, travelClassCode) {
  return PREMIUM_WIFI_TRAVEL_CLASSES_BY_SHIP[shipCode].includes(travelClassCode)
}

export function getShipNameByCode(code) {
  return SHIP_CODE_TO_NAME[code]
}

export function getWifiNameByCode(code, premiumWifiAvailable = false) {
  return `${SHIP_CODE_TO_WIFI_NAME[code]}${premiumWifiAvailable ? ' Premium' : ''}`
}

const FALLBACK_SHIP_CODE = 'MEGASTAR'
export function getMobileBackgroundImageUrl(code = FALLBACK_SHIP_CODE) {
  return require(`../images/ships-bg-mobile/ship-bg-mobile-${code.toLowerCase()}.jpg`)
}

export function getOngoingSail(outwardSail, returnSail, shipMoment, voyageType) {
  if (!isRoundTrip(voyageType)) return outwardSail

  return shipMoment.isBefore(moment(outwardSail.arrivalDateTime)) ? outwardSail : returnSail
}

export function sameShipForBothLegs(outwardSail, returnSail) {
  if (_.isEmpty(returnSail)) return false
  return outwardSail.shipCode === returnSail.shipCode
}
