import { mediaQueries } from '@tallink/components-lib'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Prompt } from 'react-router-dom'
import { useEffectOnce, useMedia } from 'react-use'

import { setDeliveryType, updateSalePoint } from '../../actions/actions'
import MirdicClient from '../../clients/MirdicClient'
import { DELIVERY_TYPE_ROOM, getFacilityTitle } from '../../common/constants'
import { ROUTE_CHANGE_CONFIRMATION_TYPES, getLocationChangeGuard } from '../../common/router'
import { isSalePointClosed, isSalePointUnavailable } from '../../common/sale-points'
import AppPromotion from '../../components/AppPromotion/AppPromotion'
import { GlobalToastActions } from '../../components/layout/GlobalToast/GlobalToast'
import Layout from '../../components/layout/Layout'
import PageHeaderDescription from '../../components/PageHeaderDescription/PageHeaderDescription'
import Products from '../../components/Products/Products'
import useBookingPageInit from '../../hooks/useBookingPageInit'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import useScrollToTop from '../../hooks/useScrollToTop'
import {
  getBackgroundImageUrl,
  getOrderRequest,
  getSpecialOffersProducts,
  getSpecialOffersSalePoint,
  hasSelectedProducts as hasSelectedProductsSelector,
  isSidebarOpen
} from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './SpecialOffersPage.module.scss'

export default function SpecialOffersPage() {
  useScrollToTop()
  useNativeBackButton()
  const { t } = useTranslation()
  const smUp = useMedia(mediaQueries.smUp)
  const dispatch = useDispatch()
  const { deliveryType } = useSelector(getOrderRequest)
  const { loading } = useBookingPageInit()
  const salePoint = useSelector(getSpecialOffersSalePoint)
  const sidebarOpen = useSelector(isSidebarOpen)
  const backgroundImageUrl = useSelector(getBackgroundImageUrl)
  const salePointCode = salePoint?.salePointCode
  const specialOffersProducts = useSelector(getSpecialOffersProducts)
  const hasSelectedProducts = useSelector(hasSelectedProductsSelector)
  const { hotelCode } = useParams()

  const specialOffersCategoryAvailable = salePoint?.categories[0].isAvailable

  useEffect(() => {
    if (deliveryType !== DELIVERY_TYPE_ROOM) dispatch(setDeliveryType(DELIVERY_TYPE_ROOM))
  }, [deliveryType, dispatch])

  useEffectOnce(() => {
    if (!salePointCode) return

    let needToUpdateSpecialOffers = isSalePointClosed(salePoint)

    MirdicClient.getSalePointByCodeOrId(salePointCode).then(newSalePoint => {
      if (isSalePointClosed(newSalePoint)) {
        needToUpdateSpecialOffers = true
        GlobalToastActions.show({
          children: t('salePointPage.toast.unavailable'),
          type: 'warning'
        })
      }

      if (needToUpdateSpecialOffers) dispatch(updateSalePoint(newSalePoint))
    })
    return GlobalToastActions.hide
  })

  if (loading) return <LoadingPage />

  return (
    <Layout
      backgroundImageUrl={backgroundImageUrl}
      headerProps={{ title: getFacilityTitle(hotelCode) }}
      navigationVisible
      pageTitle={t('page.specialOffers.title')}
    >
      <Prompt
        message={getLocationChangeGuard(ROUTE_CHANGE_CONFIRMATION_TYPES.REMOVE_ALL_PRODUCTS, 'SPECIAL_OFFERS_PAGE')}
        when={hasSelectedProducts || sidebarOpen}
      />
      <PageHeaderDescription
        description={t('specialOffers.header.description')}
        title={t('page.specialOffers.title')}
      />
      {!smUp && <AppPromotion />}
      <div className={s['special-offers-list']}>
        <Products
          categoryAvailable={specialOffersCategoryAvailable}
          innerPadding="top-right-bottom-left"
          orderingDisabled={isSalePointUnavailable(salePoint) || isSalePointClosed(salePoint)}
          products={specialOffersProducts}
        />
      </div>
    </Layout>
  )
}
