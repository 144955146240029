/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { getNotchHeight } from './BrowserDetector'
import { isSelenium } from './Env'

export function allowTouchMove(e) {
  e.stopPropagation()
}

export function preventInertiaScroll() {
  const top = this.scrollTop
  const totalScroll = this.scrollHeight
  const currentScroll = top + this.offsetHeight

  if (top === 0) {
    this.scrollTop = 1
  } else if (currentScroll === totalScroll) {
    this.scrollTop = top - 1
  }
}

export const addScrollPosition = () => {
  if (!window.mj.scrollPositions) window.mj.scrollPositions = []
  window.mj.scrollPositions.push(window.scrollY)
}

export const setScrollPositions = () => {
  if (!window.mj.scrollPositions) {
    window.mj.scrollPositions = []
    return
  }
  const scrollY = window.mj.scrollPositions.pop()

  setTimeout(() => window.scrollTo(window.scrollX, !scrollY ? 0 : scrollY), 0)
}

export function scrollIntoParentCard(inParentSelector, history) {
  const headerHeight = 47
  const extraOffset = 10

  if (history) {
    history.length > 2 ? history.goBack() : history.replace('/')
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const scrollToElement = document.querySelector(inParentSelector)
      if (!scrollToElement) return reject()
      smoothScrollTo(scrollToElement, window, getNotchHeight() + headerHeight + extraOffset, 1250)
      return resolve()
    }, 300)
  })
}

export function smoothScrollTo(el, context, yOffset = 0, duration = 500, callback = () => {}, withInnerScroll = false) {
  if (!context) return
  if (!el) {
    if (window.TrackJS?.console) window.TrackJS.console.error('smoothScrollTo: el is missing')
    return
  }
  const start = context === window ? window.pageYOffset : context.scrollTop
  const end = getTop(el, context, withInnerScroll) - yOffset
  const clock = Date.now()

  if (__TEST__ || isSelenium()) {
    if (context !== window) context.scrollTop = end
    else window.scrollTo(0, end)
    wrapperCallback()
    return
  }

  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    // eslint-disable-next-line func-names
    function (fn) {
      window.setTimeout(fn, 15)
    }

  if (duration === 0 || duration < 0) {
    window.scrollTo(0, end)
    return requestAnimationFrame(wrapperCallback)
  }

  step()

  function step() {
    const elapsed = Date.now() - clock
    if (context !== window) {
      context.scrollTop = position(start, end, elapsed, duration)
    } else {
      window.scrollTo(0, position(start, end, elapsed, duration))
    }

    if (elapsed > duration) {
      requestAnimationFrame(wrapperCallback)
    } else {
      requestAnimationFrame(step)
    }
  }

  function wrapperCallback() {
    callback(el)
  }
}

export function smoothScrollToTop() {
  if (typeof window !== 'undefined') smoothScrollTo(document.body, window)
}

export function scrollToEndOfElement(el) {
  const endOfElement = el.offsetTop + el.offsetHeight
  const scrolled = window.innerHeight + window.scrollY

  if (scrolled > endOfElement) return

  el.scrollIntoView({ behavior: 'smooth', block: 'end' })
}

function getTop(element, context, withInnerScroll) {
  if (context === window) return element.getBoundingClientRect().top + window.pageYOffset
  return withInnerScroll ? element.offsetTop : element.parentElement.scrollTop + element.getBoundingClientRect().top
}

function position(start, end, elapsed, duration) {
  if (elapsed > duration) return end
  return start + (end - start) * easeInOutCubic(elapsed / duration)
}

function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}
