import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './Spacer.module.scss'

export default function Spacer({ className, size }) {
  return (
    <div
      className={classNames(className, {
        [s.small]: size === 's',
        [s.medium]: size === 'm',
        [s.large]: size === 'l'
      })}
    />
  )
}

Spacer.propTypes = {
  className: MorePropTypes.className,
  size: PropTypes.string.isRequired
}
