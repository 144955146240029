import { ButtonEmpty, Icon } from '@tallink/components-lib'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import s from './TwoStepButton.module.scss'

export default function TwoStepButton({ onConfirmClick, iconType, iconColor, text, buttonColor, textColor }) {
  const [actionConfirmed, setActionConfirmed] = useState(false)
  const ref = useRef(null)

  useClickAway(ref, () => setActionConfirmed(false))

  const handleClick = () => {
    if (actionConfirmed) onConfirmClick()

    setActionConfirmed(true)
  }

  return (
    <ButtonEmpty
      buttonRef={ref}
      className={classNames(s['two-step-button'], {
        [s.clicked]: actionConfirmed,
        [s['caribbean-green']]: buttonColor === 'caribbean-green',
        [s['new-york-pink']]: buttonColor === 'new-york-pink'
      })}
      onClick={handleClick}
    >
      <Icon color={iconColor} type={iconType} />
      <p
        className={classNames(s.text, {
          [s.clicked]: actionConfirmed,
          [s.buoy]: textColor === 'buoy',
          [s['new-york-pink']]: textColor === 'new-york-pink'
        })}
      >
        {text}
      </p>
    </ButtonEmpty>
  )
}

TwoStepButton.propTypes = {
  buttonColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired
}
