import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './ButtonEmpty.module.scss'

export default function ButtonEmpty({ children, className, disabled, onClick, buttonRef }) {
  return (
    <button className={classNames(s.button, className)} disabled={disabled} onClick={onClick} ref={buttonRef}>
      {children}
    </button>
  )
}

ButtonEmpty.propTypes = {
  buttonRef: PropTypes.shape({ current: PropTypes.any }),
  children: PropTypes.node,
  className: MorePropTypes.className,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}
