import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import s from './Rating.module.scss'
import RatingIcons from './RatingIcons'

const nameSizeToClass = {
  default: s.default,
  m: s.medium,
  l: s.large
}

const colorToClass = {
  default: undefined,
  white: s.white
}

export default function Rating({
  align = 'left',
  color = 'default',
  inline = false,
  direction = 'row',
  iconSize,
  name,
  nameSize = 'default',
  rating
}) {
  const mainClassNames = classNames(s.rating, colorToClass[color])
  const contentClassNames = classNames(s.content, {
    [s.center]: align === 'center',
    [s.column]: direction === 'column',
    [s.row]: direction === 'row',
    [s.inline]: inline
  })
  const hotelNameClasses = classNames(s.name, nameSizeToClass[nameSize], colorToClass[color])

  return (
    <div className={mainClassNames}>
      <div className={contentClassNames}>
        <div className={hotelNameClasses}>{name}</div>
        <RatingIcons color={color} iconSize={iconSize} inline={inline} rating={rating} />
      </div>
    </div>
  )
}

Rating.propTypes = {
  align: PropTypes.oneOf(['left', 'center']),
  color: PropTypes.oneOf(['default', 'white']),
  direction: PropTypes.oneOf(['row', 'column']),
  iconSize: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameSize: PropTypes.oneOf(['default', 'm', 'l']),
  rating: PropTypes.number.isRequired
}
