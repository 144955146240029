import { configureStore } from '@reduxjs/toolkit'

import { getSummary } from './actions/actions'
import reducers from './reducers/reducers'

export default function createStore() {
  const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production'
  })

  store.subscribe(() => {
    store.dispatch(getSummary())
  })

  return store
}
