import NativeAdapter from '@tallink/native-adapter'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { useLocation } from 'react-router-dom'

import { addIgnoreNativeHistory, removeIgnoreNativeHistory } from '../common/router'
import { ROUTES } from '../common/routes'
import { hasSelectedProducts as hasSelectedProductsSelector, isSidebarOpen } from '../selectors/selectors'

export default function useNativeBackButton() {
  const history = useHistory()
  const location = useLocation()
  const sidebarOpen = useSelector(isSidebarOpen)
  const hasSelectedProducts = useSelector(hasSelectedProductsSelector)
  const servicePageMatch = useRouteMatch(ROUTES.SERVICE_PAGE)

  useEffect(() => {
    if (hasSelectedProducts || sidebarOpen || servicePageMatch) {
      NativeAdapter.hideNativeBackButton()
      removeIgnoreNativeHistory(history, location)
    } else {
      NativeAdapter.showNativeBackButton()
      addIgnoreNativeHistory(history, location)
    }

    return NativeAdapter.hideNativeBackButton
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedProducts, servicePageMatch, sidebarOpen])
}
