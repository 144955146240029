import { Spinner } from '@tallink/components-lib'
import classNames from 'classnames'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formattedPrice } from '../../common/strings'
import { getSummary } from '../../selectors/selectors'
import s from './TotalPrice.module.scss'

export default function TotalPrice({ lightTheme, subtractFee, navigationVisible }) {
  const { totalPrice, loading, deliveryFee } = useSelector(getSummary)
  const { t } = useTranslation()

  if (!loading && !totalPrice) return null

  const totalPriceWithDeliveryFeeCorrection = totalPrice - (subtractFee ? deliveryFee?.value ?? 0 : 0)
  const roundedTotalPrice = Number(totalPriceWithDeliveryFeeCorrection.toFixed(2))

  return (
    <div
      className={classNames(s['total-price'], {
        [s['light-theme']]: lightTheme,
        [s['stick-to-bottom']]: !navigationVisible
      })}
    >
      <span className={s.label}>{loading ? t('price.updating') : `${t('price.total')}:`}</span>
      {loading ? (
        <Spinner className={s.spinner} />
      ) : (
        <span className={s.price}>{formattedPrice(roundedTotalPrice, i18n.language)}</span>
      )}
    </div>
  )
}

TotalPrice.propTypes = {
  lightTheme: PropTypes.bool.isRequired,
  navigationVisible: PropTypes.bool,
  subtractFee: PropTypes.bool.isRequired
}
