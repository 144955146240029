import { Icon } from '@tallink/components-lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'

import { ORDER_TAB_NAME } from '../../common/constants'
import { IGNORE_NATIVE_HISTORY_KEY, addQueryParams } from '../../common/router'
import { ROUTES } from '../../common/routes'
import { isSpecialOffersSalePoint } from '../../common/sale-points'
import ButtonWithRouter from '../../components/common/ButtonWithRouter'
import ConfirmationPanel from '../../components/ConfirmationPanel/ConfirmationPanel'
import Layout from '../../components/layout/Layout'
import useConfirmationPageInit from '../../hooks/useConfirmationPageInit'
import { getBackgroundImageUrl } from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './ConfirmationPage.module.scss'

export default function ConfirmationPage() {
  const { t } = useTranslation()
  const location = useLocation()

  const { loading, orderInformation } = useConfirmationPageInit()
  const backgroundImageUrl = useSelector(getBackgroundImageUrl)

  if (loading) return <LoadingPage />

  const isQueen = orderInformation.hotelCode === 'queen'

  const pathToSalePoint = isSpecialOffersSalePoint({ type: orderInformation.salePointType })
    ? generatePath(ROUTES.SPECIAL_OFFERS_PAGE, {
        hotelCode: orderInformation.hotelCode
      })
    : generatePath(ROUTES.SERVICE_PAGE, {
        hotelCode: orderInformation.hotelCode,
        salePointCode: orderInformation.salePointCode,
        tabName: ORDER_TAB_NAME
      })

  const searchParams = isQueen
    ? `?${addQueryParams(location.search, { [IGNORE_NATIVE_HISTORY_KEY]: '' }).toString()}`
    : ''

  return (
    <Layout
      backgroundImageUrl={backgroundImageUrl}
      headerVisible={false}
      navigationVisible={false}
      pageTitle={t('confirmationPage.title')}
    >
      <div className={s['confirmation-page']}>
        <div className={s.close}>
          <ButtonWithRouter
            changeLocation
            className={s['close-button']}
            href={pathToSalePoint + searchParams}
            preserveParams={!isQueen}
          >
            <Icon color="light-slate-gray" size="s" type="cross" />
          </ButtonWithRouter>
        </div>
        <ConfirmationPanel orderInformation={orderInformation} />
        <div className={s['confirmation-button']}>
          <ButtonWithRouter changeLocation fullWidth href={pathToSalePoint + searchParams} preserveParams={!isQueen}>
            {t('confirmationPage.button')}
          </ButtonWithRouter>
        </div>
      </div>
    </Layout>
  )
}
