import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './BookTable.module.scss'

export default function BookTable({ workingSchedule, hotelCode }) {
  const { t } = useTranslation()

  return (
    <div className={s['book-table']}>
      <div className={s.title}>{t('services.bookTable.title')}</div>
      <div className={s['opening-times']}>
        {workingSchedule.length === 1 ? (
          <>
            <div className={s.title}>{t('services.bookTable.timeTitle')}</div>
            <div className={s.value}>{workingSchedule[0].time}</div>
          </>
        ) : (
          <>
            <div className={s.title}>{t('services.bookTable.multipleTimesTitle')}</div>
            <div className={s.value}>
              {workingSchedule.map(({ date, time }) => (
                <div key={date + time}>
                  {date}: {time}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={s['reception-phone']}>
        <div className={s.title}>{t('services.salePoint.phone')}:</div>
        <a className={s.value} href={`tel:${t(`contact.hotel.${hotelCode}.receptionPhone`)}`}>
          {t(`contact.hotel.${hotelCode}.receptionPhone`)}
        </a>
      </div>
      <div className={s['post-scriptum']}>{t('services.bookTable.postScriptum')}</div>
    </div>
  )
}

BookTable.propTypes = {
  hotelCode: PropTypes.string,
  workingSchedule: PropTypes.array
}
