import { Rating, Spacer, TallinkSiljaLogo } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFacilityTitle } from '../../../common/constants'
import SsoSignInButton from '../../common/SsoSignInButton'
import InfoPanel from '../InfoPanel/InfoPanel'
import s from './AnonymousPanel.module.scss'

export default function AnonymousPanel({ hotelCode }) {
  const { t } = useTranslation()

  return (
    <div className={s['anonymous-panel']}>
      <InfoPanel direction="column" isInfo>
        <div className={s.logo}>
          <TallinkSiljaLogo />
        </div>
        <p className={s['welcome-to']}>{t('rightMenu.anonymousUser.welcome')}</p>
        <Rating
          align="center"
          direction="column"
          iconSize="s"
          name={getFacilityTitle(hotelCode)}
          nameSize="m"
          rating={4}
        />
        <Spacer size="m" />
        <SsoSignInButton fullWidth />
      </InfoPanel>
    </div>
  )
}

AnonymousPanel.propTypes = {
  hotelCode: PropTypes.string.isRequired
}
