import { createReducer } from '@reduxjs/toolkit'

import {
  deleteStoredPaymentMethod,
  receivedProfile,
  receivedStoredPaymentMethods,
  signedIn,
  signedOut
} from '../actions/actions'

const initialState = { clubOneLevel: 'NONE', token: undefined, userId: undefined }

const user = createReducer(initialState, {
  [receivedProfile]: (state, action) => ({ ...state, ...action.payload }),
  [signedIn]: (state, action) => ({ ...state, ...action.payload }),
  [signedOut]: () => initialState,
  [receivedStoredPaymentMethods]: (state, { payload }) => ({
    ...state,
    storedPaymentMethods: payload.map(paymentMethod => ({
      ...paymentMethod,
      value: `**** **** **** ${paymentMethod.lastFour}`
    }))
  }),
  [deleteStoredPaymentMethod]: (state, { payload }) => ({
    ...state,
    storedPaymentMethods: state.storedPaymentMethods.filter(({ id }) => payload !== id)
  })
})

export default user
