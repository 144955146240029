/* eslint-disable no-param-reassign */
export function getQueryParams() {
  if (typeof window === 'undefined') return {}

  return paramsOf(window.location.href)
}

export function paramsOf(url) {
  if (!url) return {}
  const urlAndParams = url.split('?')
  if (urlAndParams.length < 2) return {}

  return parseParams(urlAndParams[1].split('#')[0])
}

export function hashParamsOf(hash) {
  if (!hash || hash.charAt(0) !== '#') return {}
  return parseParams(hash.slice(1))
}

export function updateHashParams(str) {
  // eslint-disable-next-line no-restricted-globals
  history.pushState(null, null, ` #${str}`)
}

function parseParams(str) {
  return str
    .split('&')
    .filter(pair => pair !== '')
    .map(pair => pair.split('='))
    .reduce((result, pair) => {
      result[pair[0]] = decodeURIComponent(pair[1] || '')
      return result
    }, {})
}

export function stringify(obj) {
  if (!obj) return ''

  return Object.keys(obj)
    .sort(a => (a.startsWith('/') ? -1 : 0))
    .filter(key => obj[key] !== undefined)
    .map(key => key + (obj[key] !== '' ? `=${encodeURIComponent(obj[key])}` : ''))
    .join('&')
}

export function changeLocation(url) {
  window.location[__TEST__ ? 'hrefSpy' : 'href'] = url
}

export function getLocation() {
  return window[__TEST__ ? 'locationMock' : 'location']
}

export function openNewWindow(url) {
  // eslint-disable-next-line no-unused-expressions
  __TEST__ ? (window.location.hrefSpy = url) : window.open(url, '_blank')
}

export function hasParameter(parameter) {
  return getQueryParams()[parameter] !== undefined
}

export function parseUrl(url) {
  const aEl = document.createElement('a')
  aEl.href = url
  const { protocol, hostname, port, pathname, search, hash, host } = aEl

  return {
    protocol,
    hostname,
    port: port && port !== '80' && port !== '443' ? port : '',
    pathname: pathname.startsWith('/') ? pathname : `/${pathname}`,
    search,
    hash,
    host
  }
}

export function updateParamsInUrl(url, searchParams, hashParams) {
  const urlData = parseUrl(url)
  const searchString = searchParams ? stringify(searchParams) : urlData.search.substring(1)
  const hashString = stringify({ ...hashParamsOf(urlData.hash), ...hashParams })

  return [
    urlData.protocol,
    '//',
    urlData.hostname,
    urlData.port ? `:${urlData.port}` : '',
    urlData.pathname,
    searchString ? '?' : '',
    searchString,
    hashString ? '#' : '',
    hashString
  ].join('')
}

export function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
}

export function removeParamFromUrl(url, paramToRemove) {
  const [urlPath, params] = decodeURIComponent(url).split('?')
  if (!params) return urlPath
  const [queryParams, hashParams] = params.split('#')
  const updatedParams = queryParams
    .split('&')
    .filter(pair => pair !== '')
    .map(pair => pair.split('='))
    .filter(pair => pair[0].toLowerCase() !== paramToRemove.toLowerCase())
    .reduce((result, pair) => {
      result[pair[0]] = pair[1] || ''
      return result
    }, {})

  return [urlPath, stringify(updatedParams)].join('?') + (hashParams ? `#${hashParams}` : '')
}

export function replaceHistoryWithoutQueryParam(param) {
  if (!window.location.href.includes(param)) return
  const newUrl = removeParamFromUrl(window.location.href, param)
  window.history.replaceState(null, '', newUrl)
}

export function encodeBase64Param(param) {
  if (!param) return

  return encodeURIComponent(window.btoa(param))
}

export function safeDecodeBase64Param(param) {
  if (!param) return

  const str = decodeURIComponent(param)
  try {
    return window.atob(str)
  } catch (e) {
    return str
  }
}

export const reservationChangesTitles = {
  departure: 'departure',
  landServices: 'landServices',
  onboardServices: 'onboardServices',
  passengers: 'passengers',
  vehicles: 'vehicles',
  travelClass: 'travelClass',
  meals: 'meals',
  extraServices: 'extraServices'
}

export const IGNORE_NATIVE_HISTORY_KEY = 'ignoreHistoryOnNativeBackButtonClick'
