import '../../scss/layout.global.scss'

import { mediaQueries } from '@tallink/components-lib'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useRouteMatch } from 'react-router'
import { useMedia } from 'react-use'

import { ROUTES } from '../../common/routes'
import Footer from './Footer/Footer'
import GlobalModal from './GlobalModal/GlobalModal'
import Header, { headerPropTypes } from './Header/Header'
import s from './Layout.module.scss'

export default function Layout({
  backgroundImageUrl,
  children,
  flexContent = false,
  headerProps,
  headerVisible = true,
  navigationVisible,
  pageTitle,
  stickBackground
}) {
  const shoppingPageMatch = useRouteMatch(ROUTES.SHOPPING_PAGE)
  const confirmationPageMatch = useRouteMatch(ROUTES.CONFIRMATION_PAGE)
  const paymentRejectedPageMatch = useRouteMatch(ROUTES.PAYMENT_REJECTED_PAGE)
  const smUp = useMedia(mediaQueries.smUp)

  const showAsPopup = smUp && (shoppingPageMatch || confirmationPageMatch || paymentRejectedPageMatch)

  return (
    <>
      <Helmet titleTemplate="%s | Tallink Silja">
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <div
        className={classNames(s.background, { [s['stick-bg-to-top']]: stickBackground || showAsPopup })}
        style={{
          '--bg-image': `url(${backgroundImageUrl})`
        }}
      >
        <main className={classNames(s.page, { [s['show-as-popup']]: showAsPopup })}>
          {headerVisible && <Header {...headerProps} />}
          <section className={classNames(s['page-content'], { [s['flex-content']]: flexContent })}>{children}</section>
          <Footer navigationVisible={navigationVisible} />
        </main>
      </div>
      <GlobalModal />
    </>
  )
}

Layout.propTypes = {
  backgroundImageUrl: PropTypes.string,
  children: PropTypes.node,
  flexContent: PropTypes.bool,
  headerProps: PropTypes.shape(headerPropTypes),
  headerVisible: PropTypes.bool,
  navigationVisible: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  stickBackground: PropTypes.bool
}
