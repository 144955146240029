import { matchPath } from 'react-router'

export const ROUTES = {
  CONFIRMATION_PAGE: '/confirmation/:orderToken',
  CONTACT_PAGE: '/hotel/:hotelCode/contact',
  HOTEL: '/hotel/:hotelCode',
  SERVICES_PAGE: '/hotel/:hotelCode/services',
  SERVICE_PAGE: '/hotel/:hotelCode/services/:salePointCode/:tabName',
  SHOPPING_PAGE: '/hotel/:hotelCode/checkout',
  SPECIAL_OFFERS_PAGE: '/hotel/:hotelCode/special-offers',
  PAYMENT_REJECTED_PAGE: '/payment-rejected/:orderToken'
}

export const getHotelCode = history => matchPath(history.location.pathname, ROUTES.HOTEL).params.hotelCode

export const isRoute = (history, route) => !!matchPath(history.location.pathname, route)
