import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './Tabs.module.scss'

export default function Tabs({ children, className }) {
  return <div className={classNames(s.tabs, className)}>{children}</div>
}

Tabs.propTypes = {
  children: PropTypes.node,
  className: MorePropTypes.className
}
