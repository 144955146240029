import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './Tab.module.scss'

export default function Tab({ children, className, disabled, onClick, selected, selectedClassName = '' }) {
  return (
    <button
      className={classNames(s.tab, className, {
        [s.selected]: selected,
        [selectedClassName]: selected,
        [s.disabled]: disabled
      })}
      onClick={handleClick}
      tabIndex={disabled || selected ? -1 : 0}
    >
      {children}
    </button>
  )

  function handleClick() {
    if (disabled || selected) return
    onClick()
  }
}

Tab.propTypes = {
  children: PropTypes.node,
  className: MorePropTypes.className,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  selectedClassName: MorePropTypes.className
}
