export default function Validators() {
  const EMAIL_VALIDITY_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const NAME_VALIDITY_REGEXP = /^[a-zöäüõžšŠåąčęėįšųūžāčēģīķļņūžōŗéáàë. -]*$/i
  const LICENSE_PLATE_VALIDITY_REGEXP = /^[a-zöäüõžšŠåąčęėįšųūžāčēģīķļņūžōŗéáàë0-9 -]+$/i

  function isEmailValid(value) {
    return !!value && EMAIL_VALIDITY_REGEXP.test(value)
  }

  function isNameValid(value) {
    return !!value && NAME_VALIDITY_REGEXP.test(value)
  }

  function isLicensePlateValid(value) {
    return !!value && LICENSE_PLATE_VALIDITY_REGEXP.test(value)
  }

  function areLicensePlatesValid(licensePlates) {
    return Object.values(licensePlates)
      .filter(a => a !== undefined)
      .every(isLicensePlateValid)
  }

  return {
    isEmailValid,
    isNameValid,
    areLicensePlatesValid,
    isLicensePlateValid,
    EMAIL_VALIDITY_REGEXP,
    NAME_VALIDITY_REGEXP,
    LICENSE_PLATE_VALIDITY_REGEXP
  }
}
