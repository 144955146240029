import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'underscore'

import MorePropTypes from '../../common/MorePropTypes'

export function DateTime({ children, className, format }) {
  const date = moment(children)
  const classes = classNames('date-time', className)
  const items = _.isString(format)
    ? date.format(format)
    : _.map(format, (format, itemClass) => (
        <DateTimeItem date={date.format(format)} itemClass={itemClass} key={itemClass} />
      ))

  return (
    <div className={classes} data-iso-datetime={date.format('YYYY-MM-DD[T]HH:mm')}>
      {items}
    </div>
  )
}

export default React.memo(DateTime, _.isEqual)

DateTime.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])
}

function DateTimeItem({ itemClass, date }) {
  return <div className={itemClass}>{date}</div>
}

DateTimeItem.propTypes = {
  date: PropTypes.string.isRequired,
  itemClass: PropTypes.string.isRequired
}

export function WeekRange({ date, format, delimiter }) {
  const ISO_DATE_FORMAT = 'YYYY-MM-DD'

  const start = moment(date, ISO_DATE_FORMAT).startOf('isoWeek')
  const end = moment(date, ISO_DATE_FORMAT).endOf('isoWeek')

  return (
    <>
      <DateTime className="date-time" format={format}>
        {start}
      </DateTime>
      {delimiter}
      <DateTime className="date-time" format={format}>
        {end}
      </DateTime>
    </>
  )
}

WeekRange.propTypes = {
  date: MorePropTypes.dateString,
  delimiter: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired
}
