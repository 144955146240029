import PropTypes from 'prop-types'
import React from 'react'

import Icon from '../Icon/Icon'
import s from './ErrorMessage.module.scss'

export default function ErrorMessage({ message }) {
  return (
    <div className={s.error}>
      <Icon type="exclamation-triangle-fill" />
      <span className={s.message} data-testid="error-message">
        {message}
      </span>
    </div>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string
}
