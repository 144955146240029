import { v4 as uuidv4 } from 'uuid'

import { DIGITAL_KEY_ERRORS, MESSAGES_ALLOWED_TO_REPEAT } from './constants'

export const EMPTY_USER = undefined

export function isNative() {
  if (localStorage?.getItem('assa.mockNative') === 'true') {
    mockNative()
    return true
  }
  return !!global?.native
}

export function isIosNative() {
  const [platform] = getNativeVersion() || []

  return platform === 'ios'
}

export function isAndroidNative() {
  const [platform] = getNativeVersion() || []

  return platform === 'android'
}

export function isNativeAndWalletSupported() {
  const isWalletSupported = window.native?.isWalletSupported

  return typeof isWalletSupported === 'function'
    ? Boolean(window.native.isWalletSupported())
    : Boolean(isWalletSupported)
}

export function isNativeAppSupports(feature) {
  if (typeof feature !== 'string') throw new Error('isNativeAppSupports can not work with non string value')
  if (!featuresToVersions[feature]) throw new Error('isNativeAppSupports can not find feature')

  const [platform, platformVersion] = getNativeVersion() || []
  if (!platform || !platformVersion) return false

  const featureVersionDescriptor = featuresToVersions[feature][platform]
  if (!featureVersionDescriptor) return false

  const [symbol, version] = featureVersionDescriptor.split(' ')
  if (!symbolToMethod[symbol]) throw new Error('isNativeAppSupports can not find appropriate method')

  return symbolToMethod[symbol](platformVersion, version)
}

export function getUser() {
  if (!isNative()) return EMPTY_USER
  try {
    const { userLogin, ssoToken } = JSON.parse(global.native.getUserLogin())
    return {
      clubOneLevel: userLogin.clubOneLevel,
      clubOnePoints: userLogin.clubOnePoints,
      email: userLogin.email,
      ssoToken
    }
  } catch (e) {
    return EMPTY_USER
  }
}

function hasNativeSendMessage() {
  return isNative() && typeof global.native?.sendMessage === 'function'
}

let lastMessage

export function sendMessageToNative(message, payload) {
  const currentMessage = { message, payload }

  if (!MESSAGES_ALLOWED_TO_REPEAT.includes(message) && JSON.stringify(currentMessage) === JSON.stringify(lastMessage)) {
    logConsole('skipped native.sendMessage()', { message, payload })
    return
  }

  logConsole('sendMessageToNative', JSON.stringify({ message, payload }))

  if (hasNativeSendMessage()) {
    if (payload) global.native.sendMessage(message, payload)
    else global.native.sendMessage(message)

    lastMessage = currentMessage
  }
}

export function hideNativeBackButton() {
  sendMessageToNative('hideBackButton')
}

export function showNativeBackButton() {
  sendMessageToNative('showBackButton')
}

export function sendOnloadToNative() {
  sendMessageToNative('onload')
}

export function sendCalendarEventsToNative(events) {
  sendMessageToNative('addToCalendar', events)
}

/**
 * @typedef Result
 * @property {boolean} response - True if user can start door opening.
 * @property {number|null} [errorCode] - The errorCode if the user can't start door opening.
 * @property {string|null} [errorMessage] - The errorMessage if the user can't start door opening.
 */

/**
 * Check if user can start door opening.
 * @param {Object} [params] - Params for the request to check if user can start door opening.
 * @param {string} params.tenantToken - Tenant token.
 * @param {number} params.reservationId - Reservation id.
 * @param {string} params.email - Email address.
 * @param {string} params.facilityCode - Hotel code or ship code where the door is located.
 * @param {string} params.doorNr - Hotel room number or ship cabin number.
 * @param {string} params.startTime - ISO 8601 datetime when the door should start opening.
 * @param {string} params.expireTime - ISO 8601 datetime when the door opening should expire.
 * @param {string} params.signature - SHA-256 signature to validate start and expire time.
 * @return {Promise<Result>}
 */
export function canStartDoorOpening(params) {
  if (!isNative()) {
    return Promise.resolve({ response: false, errorCode: DIGITAL_KEY_ERRORS.NOT_NATIVE, errorMessage: 'NOT_NATIVE' })
  }

  try {
    return canStartDoorOpeningNative(params)
  } catch (e) {
    logConsole(`Error calling native.canStartDoorOpening(${JSON.stringify(params, null, 2)}): ${e}`)
    return Promise.resolve({ response: false, errorCode: DIGITAL_KEY_ERRORS.NOT_SET, errorMessage: 'NOT_SET' })
  }
}

function canStartDoorOpeningNative(params) {
  return new Promise(resolve => {
    const callId = uuidv4()

    window.addEventListener('message', listener, { passive: true })

    logAssa(`sending msg to native: canStartDoorOpening callId=${callId}, params=${JSON.stringify(params)}`)

    const payload = { callId, ...params }
    sendMessageToNative('canStartDoorOpening', isIosNative() ? payload : JSON.stringify(payload))

    function listener(event) {
      if (event?.data?.callId === callId) {
        logAssa(
          `received msg from native: canStartDoorOpening callId=${callId}, response event.data=${JSON.stringify(
            event.data
          )}}`
        )
        resolve(event.data)
        window.removeEventListener('message', listener)
      }
    }
  })
}

export function openDoor(facilityCode, doorNr, reservationId) {
  if (!isNative()) {
    logConsole('openDoor() works only in native app')
    return
  }

  logAssa(`calling openDoor(${facilityCode}, ${doorNr}, ${reservationId})`)
  if (isIosNative()) openDoorIos()
  else openDoorAndroid()

  function openDoorIos() {
    sendMessageToNative('openDoor', {
      facilityCode,
      doorNr,
      reservationId
    })
  }

  function openDoorAndroid() {
    if (typeof global.native?.openDoor !== 'function') {
      logConsole('native.openDoor() is not a function')
      return
    }

    try {
      global.native.openDoor(facilityCode, doorNr, reservationId)
    } catch (e) {
      logConsole('Error calling native.openDoor()', e)
    }
  }
}

export function getIsTrackingAllowed() {
  if (!isNative()) {
    logConsole('getIsTrackingAllowed() works only in native app')
    return
  }

  if (typeof global.native?.getIsTrackingAllowed !== 'function') {
    logConsole('native.getIsTrackingAllowed() is not a function')
    return
  }

  try {
    return global.native.getIsTrackingAllowed()
  } catch (e) {
    logConsole('Error calling native.getIsTrackingAllowed()', e)
  }
}

function getNativeVersion() {
  let fromNative = global.native?.getVersion?.()
  if (typeof fromNative !== 'string' && Object.prototype.toString.call(fromNative) !== '[object Object]') return null

  if (typeof fromNative === 'string') {
    try {
      fromNative = JSON.parse(fromNative)
    } catch (e) {
      return null
    }
  }

  const versions = fromNative && Object.entries(fromNative)
  return versions?.[0]
}

export function logAssa(msg) {
  if (localStorage.getItem('assa.enableLogging') !== 'true') return

  const logs = localStorage.getItem('assa.logs')
  const newLogLine = [new Date().toISOString(), msg].join('\t')
  logConsole(['[MJ ASSA]', newLogLine].join(' '))
  localStorage.setItem('assa.logs', `${logs ? `${logs}\n` : ''}${newLogLine}`)
}

function logConsole(...args) {
  if (__TEST__) return
  // eslint-disable-next-line no-console
  console.log.call(console, args)
}

export const featuresToVersions = {
  addToCalendar: { android: '>= 2.7.0', ios: '>= 2.8.0' },
  appleWallet: { ios: '>= 2.4.0' },
  windowOpen: { android: '>= 2.3.1', ios: '>= 2.4.5' },
  openLoginCard: { android: '>= 2.6.0', ios: '>= 2.7.0' }
}

export const symbolToMethod = {
  '>': (current, target) => compareVersions(current, target) === 1,
  '<': (current, target) => compareVersions(current, target) === -1,
  '>=': (current, target) => [1, 0].includes(compareVersions(current, target)),
  '<=': (current, target) => [-1, 0].includes(compareVersions(current, target))
}

export function compareVersions(base, target) {
  if (!base || !target) return null

  const baseVersion = base.replace(/(^\.|\.$)/gm, '')
  const targetVersion = target.replace(/(^\.|\.$)/gm, '')
  const baseArr = baseVersion.split('.')
  const targetArr = targetVersion.split('.')
  const length = baseArr.length > targetArr.length ? baseArr.length : targetArr.length

  for (let i = 0; i < length; i += 1) {
    if (!targetArr[i] || parseInt(baseArr[i], 10) > parseInt(targetArr[i], 10)) return 1
    if (!baseArr[i] || parseInt(baseArr[i], 10) < parseInt(targetArr[i], 10)) return -1
  }
  return 0
}

export function resetLastMessage() {
  lastMessage = null
}

function mockNative() {
  if (global.native?.mocked) return

  if (!global.native) {
    global.native = { mocked: true }
  }

  if (!global.native.sendMessage) {
    global.native.sendMessageCalls = []
    global.native.sendMessage = (msg, payload) => {
      logConsole('called native.sendMessage()', { msg, payload })
      global.native.sendMessageCalls.push({ msg, payload })

      if (msg === 'canStartDoorOpening') {
        const { callId } = JSON.parse(payload)
        const result =
          localStorage.getItem('assa.mockNative.returnResult') === 'false'
            ? { response: false, errorCode: -666, errorMessage: 'Mocked error 😈' }
            : { response: true, errorCode: null, errorMessage: null }

        const msgPayload = { callId, ...result }

        setTimeout(() => window.postMessage(msgPayload), 5000)
      }
    }
  }

  if (!global.native.openDoor) {
    global.native.openDoorCalls = []
    global.native.openDoor = (...args) => {
      logConsole('fake openDoor called:', args)
      global.native.openDoorCalls.push(...args)
    }
  }

  if (!global.native.getUserLogin) {
    global.native.getUserLogin = () => {
      const fakeUser = {
        userLogin: {
          clubOneLevel: 'GOLD',
          clubOnePoints: 10000,
          email: 'tut@tut.by'
        },
        ssoToken: localStorage.getItem('assa.mockNative.ssoToken') || 'put-token-to-LS'
      }
      logConsole('called native.getUserLogin(), returning:', fakeUser)

      return JSON.stringify(fakeUser)
    }
  }
}
