import * as PropTypes from 'prop-types'
import React, { useState } from 'react'

import { generateHtmlId } from '../../common/accessibility'
import MorePropTypes from '../../common/MorePropTypes'
import FieldText from '../FieldText/FieldText'
import Select from '../Select/Select'
import s from './FormRow.module.scss'

export default function FormRow({ id, label, children, error, invalid, optionalLabel }) {
  const [htmlId] = useState(id || generateHtmlId())
  const childrenWithId = React.cloneElement(children, { id: htmlId })

  return (
    <div className={s['form-row']}>
      <label className={s.label} htmlFor={htmlId}>
        <span>{label}:</span>
        {optionalLabel && <span className={s.optional}>{optionalLabel}</span>}
      </label>
      {childrenWithId}
      {invalid && error}
    </div>
  )
}

FormRow.propTypes = {
  children: MorePropTypes.componentOfType([FieldText, Select]),
  error: PropTypes.node,
  id: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  optionalLabel: PropTypes.string
}
