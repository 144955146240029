import { createReducer } from '@reduxjs/toolkit'

import { receivedOrderInformation, receivedOrderToken } from '../actions/actions'

export const initialState = null

const orderInformation = createReducer(initialState, {
  [receivedOrderInformation]: (state, { payload }) => payload,
  [receivedOrderToken]: () => initialState
})

export default orderInformation
