const DEFAULT_FACILITY_TITLE = 'Tallink order service'
export const getFacilityTitle = (facilityCode = '') =>
  ({
    city: 'Tallink City Hotel',
    queen: 'Baltic Queen',
    spa: 'Tallink Spa & Conference Hotel'
  }[facilityCode.toLowerCase()] || DEFAULT_FACILITY_TITLE)

export const DEFAULT_HOTEL_CODE = 'city'

export const SPECIAL_OFFERS_TYPE = 'SPECIAL_OFFERS'
export const DEFAULT_TYPE = 'DEFAULT'
export const BREAKFAST_TYPE = 'BREAKFAST'

export const ORDER_TAB_NAME = 'order'
export const BOOK_TAB_NAME = 'book'

export const DELIVERY_TYPE_ROOM = 'ROOM'
export const DELIVERY_TYPE_TABLE = 'TABLE'

export const PAY_LATER = 'PAY_LATER'
export const PAY_NOW = 'PAY_NOW'

export const SALE_POINT_UNAVAILABLE = 'SALE_POINT_UNAVAILABLE'
export const PRODUCT_UNAVAILABLE = 'PRODUCT_UNAVAILABLE'
export const HOTEL_ROOM_NOT_FOUND = 'HOTEL_ROOM_NOT_FOUND'
export const SALE_POINT_TABLE_NOT_FOUND = 'SALE_POINT_TABLE_NOT_FOUND'
export const TOTAL_PRICE_MISMATCH = 'TOTAL_PRICE_MISMATCH'
export const STORED_PAYMENT_METHODS = 'stored-payment-methods'

export const TIME_TO_LOGOUT = '10:00'
export const SALE_POINT_UPDATE_INTERVAL = 9 * 60 * 1000
export const ORDER_REQUEST_LIFETIME_MINUTES = 15 * 60 * 1000

export const MAX_COMMENT_LENGTH = 500

export const SHOPPING_CART_WARNINGS = {
  HAS_PRODUCT_WITH_ZERO_QUANTITY: 'HAS_PRODUCT_WITH_ZERO_QUANTITY',
  HAS_PRODUCT_WITH_PRICE_CHANGE: 'HAS_PRODUCT_WITH_PRICE_CHANGE',
  HAS_PRODUCT_WITH_ALCOHOL_RESTRICTION: 'HAS_PRODUCT_WITH_ALCOHOL_RESTRICTION'
}

export const errorHandlerIgnoredErrorCodes = [
  SALE_POINT_UNAVAILABLE,
  PRODUCT_UNAVAILABLE,
  HOTEL_ROOM_NOT_FOUND,
  SALE_POINT_TABLE_NOT_FOUND
]

export const errorHandlerIgnoredUrls = [STORED_PAYMENT_METHODS]
