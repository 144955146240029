import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import Icon from '../Icon/Icon'
import s from './ButtonIcon.module.scss'

const colorToClass = {
  'light-slate-gray': undefined,
  'new-york-pink': undefined,
  buoy: undefined,
  default: undefined,
  white: s.white
}

export default function ButtonIcon({ buttonRef, className, color = 'default', disabled, iconSize, iconType, onClick }) {
  return (
    <button
      className={classNames(s['button-icon'], colorToClass[color], className)}
      disabled={disabled}
      onClick={onClick}
      ref={buttonRef}
    >
      <Icon color={color} size={iconSize} type={iconType} />
    </button>
  )
}

ButtonIcon.propTypes = {
  buttonRef: PropTypes.func,
  className: MorePropTypes.className,
  color: PropTypes.oneOf(Object.keys(colorToClass)),
  disabled: PropTypes.bool,
  iconSize: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
