import { Spinner } from '@tallink/components-lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { getFacilityTitle } from '../../common/constants'
import { getBackgroundImageUrl } from '../../common/hotels'
import Layout from '../../components/layout/Layout'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import s from './ContactPage.module.scss'

export default function ContactPage() {
  useNativeBackButton()
  const { hotelCode } = useParams()
  const { t } = useTranslation()

  return (
    <Layout
      backgroundImageUrl={getBackgroundImageUrl(hotelCode)}
      flexContent
      headerProps={{ title: getFacilityTitle(hotelCode) }}
      navigationVisible
      pageTitle={t('contactPage.title')}
    >
      <div className={s['spinner-container']}>
        <Spinner className={s.spinner} />
        <p>{t('contactPage.chatInitializing')}</p>
      </div>

      <div id="adobe-target-element" />
      <div className={s['alphachat-container']} id="alphachat-container" />
    </Layout>
  )
}
