import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import Icon from '../Icon/Icon'
import s from './BackButton.module.scss'

const colorToClass = {
  default: undefined,
  white: s.white
}

export default function BackButton({ className, color, iconType = 'chevron-left', onClick }) {
  return (
    <button className={classNames(s['back-button'], colorToClass[color], className)} onClick={onClick}>
      <Icon color={color} size="l" type={iconType} />
    </button>
  )
}

BackButton.propTypes = {
  className: MorePropTypes.className,
  color: PropTypes.oneOf(Object.keys(colorToClass)),
  iconType: PropTypes.string,
  onClick: PropTypes.func.isRequired
}
