import { Button } from '@tallink/components-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { isSsoDisabled } from '../../selectors/selectors'

export default function SsoSignInButton({ className, fullWidth }) {
  const { t } = useTranslation()
  const ssoDisabled = useSelector(isSsoDisabled)

  return (
    <Button className={className} disabled={ssoDisabled} fullWidth={fullWidth} onClick={() => window.SSO.open()}>
      {t('common.signIn')}
    </Button>
  )
}

SsoSignInButton.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool
}
