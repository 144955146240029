import { Accordion, ButtonIcon } from '@tallink/components-lib'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import _ from 'underscore'

import s from './TermsAndConditions.module.scss'

export default function TermsAndConditions({ handleCloseButtonClick }) {
  const { t } = useTranslation()
  const { hotelCode } = useParams()

  const allTAC = Object.entries(i18n.getResourceBundle(i18n.language)).filter(([key]) =>
    key.startsWith(hotelCode === 'queen' ? 'termsAndConditions.ship.item.' : 'termsAndConditions.item.')
  )

  const keys = allTAC.map(kv => kv[0]).filter(key => key.endsWith('.title'))
  const values = allTAC.map(kv => kv[0]).filter(key => key.endsWith('.text'))

  const termsAndConditions = _.zip(keys, values)

  return (
    <div className={s['terms-and-conditions']}>
      <div className={s['title-container']}>
        <p className={s.title}>{t('termsAndConditions.title')}</p>
        <ButtonIcon
          className={s['close-button']}
          color="light-slate-gray"
          iconSize="s"
          iconType="cross"
          onClick={() => handleCloseButtonClick()}
        />
      </div>
      {termsAndConditions.map(([titleKey, textKey]) => (
        <Accordion
          className={s.accordion}
          key={titleKey}
          titleAlign="left"
          titleContent={<div className={s['accordion-title']}>{t(titleKey)}</div>}
        >
          <p className={s.condition} key={textKey}>
            {t(textKey)}
          </p>
        </Accordion>
      ))}
    </div>
  )
}

TermsAndConditions.propTypes = {
  handleCloseButtonClick: PropTypes.func.isRequired
}
