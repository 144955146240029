import classNames from 'classnames'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import { ReactComponent } from './spinner-propeller.svg'
import s from './Spinner.module.scss'

export default function Spinner({ className }) {
  return <ReactComponent className={classNames(className, s.spinner)} role="progressbar" />
}

Spinner.propTypes = {
  className: MorePropTypes.className
}
