import { Icon } from '@tallink/components-lib'
import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './PaymentFailed.module.scss'

export default function PaymentFailed() {
  const { t } = useTranslation()

  return (
    <div className={s['payment-failed']}>
      <Icon className={s.icon} type="exclamation-triangle-fill" />
      <p className={s.title}>{t('paymentRejectedPage.title')}</p>
      <p className={s.description}>{t('paymentRejectedPage.description')}</p>
    </div>
  )
}
