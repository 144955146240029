import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { initApp } from '../actions/actions'
import { getSalePoints } from '../selectors/selectors'

export default function useBookingPageInit() {
  const dispatch = useDispatch()
  const history = useHistory()

  const salePoints = useSelector(getSalePoints)
  const { hotelCode } = useParams()
  const [initializing, setInitializing] = useState(false)
  const [salePointsLoadedOnInit, setSalePointsLoadedOnInit] = useState(false)

  useEffect(() => {
    if (salePoints.length === 0) {
      if (!initializing) dispatch(initApp(history, hotelCode))
      setInitializing(true)
      setSalePointsLoadedOnInit(true)
    } else {
      setInitializing(false)
    }
  }, [hotelCode, dispatch, history, initializing, salePoints])

  return { loading: initializing || salePoints.length === 0, salePoints, salePointsLoadedOnInit }
}
