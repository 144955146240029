export const MOBILE_OS = {
  ANDROID: 'android',
  IOS: 'ios',
  WINDOWS_PHONE: 'windows-phone',
  UNKNOWN: 'unknown'
}

export function getUserAgent() {
  return navigator.userAgent || navigator.vendor || window.opera
}

export function isIe(userAgent) {
  return !!ieVersionOf(userAgent)
}

export function ieVersionOf(userAgent) {
  const msie = userAgent.indexOf('MSIE ')
  if (msie > 0) return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10)

  const trident = userAgent.indexOf('Trident/')
  if (trident > 0) {
    const rv = userAgent.indexOf('rv:')
    return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10)
  }

  const edge = userAgent.indexOf('Edge/')
  if (edge > 0) return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10)

  return 0
}

export function isIos(userAgent = getUserAgent()) {
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export function getIeClassName(userAgent) {
  return isIe(userAgent) ? `ie ie${ieVersionOf(userAgent)}` : ''
}

export function getIpadClassName(userAgent) {
  return isIpad(userAgent) ? 'ipad' : ''
}

export function isIpad(userAgent = getUserAgent()) {
  return userAgent.match(/iPad/i) !== null
}

export function isIosChrome(userAgent = getUserAgent()) {
  return userAgent.match(/CriOS/i) !== null
}

export function isIosFirefox(userAgent = getUserAgent()) {
  return userAgent.match(/FxiOS/i) !== null
}

export function getBrowser() {
  const ua = navigator.userAgent
  let match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  let temp

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: temp[1] || '' }
  }
  if (match[1] === 'Chrome') {
    temp = ua.match(/\bOPR|Edge\/(\d+)/)
    if (temp !== null) return { name: 'Opera', version: temp[1] }
  }
  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((temp = ua.match(/version\/(\d+)/i)) !== null) {
    match.splice(1, 1, temp[1])
  }

  return { name: match[0], version: match[1] }
}

export function getMobileOperatingSystem(userAgent = getUserAgent()) {
  if (/windows phone/i.test(userAgent)) return MOBILE_OS.WINDOWS_PHONE

  if (/android/i.test(userAgent)) return MOBILE_OS.ANDROID

  if (isIos(userAgent)) return MOBILE_OS.IOS

  return MOBILE_OS.UNKNOWN
}

export function isAndroidStockBrowser(userAgentStr) {
  const userAgent = userAgentStr || navigator.userAgent

  const isAndroidMobile =
    userAgent.indexOf('Android') > -1 && userAgent.indexOf('Mozilla/5.0') > -1 && userAgent.indexOf('AppleWebKit') > -1

  const appleWebKitVersion = /AppleWebKit\/([\d.]+)/.exec(userAgent)
    ? parseFloat(/AppleWebKit\/([\d.]+)/.exec(userAgent)[1])
    : false

  const chromeVersion = /Chrome\/([\d.]+)/.exec(userAgent) ? parseFloat(/Chrome\/([\d.]+)/.exec(userAgent)[1]) : false

  return (isAndroidMobile && appleWebKitVersion && appleWebKitVersion < 537) || (chromeVersion && chromeVersion < 37)
}

export function isIphoneX() {
  const os = getMobileOperatingSystem()
  const ratio = window.devicePixelRatio || 1

  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio
  }

  return os === MOBILE_OS.IOS && screen.width === 1125 && screen.height === 2436
}

let notchHeight = null
export function getNotchHeight() {
  if (notchHeight !== null) return notchHeight

  const constantValue = 'calc(constant(safe-area-inset-top))'
  const envValue = 'calc(env(safe-area-inset-top))'
  const supportsConstant = CSS.supports('padding-top', constantValue)
  const supportsEnv = CSS.supports('padding-top', envValue)

  if (!supportsConstant && !supportsEnv) {
    notchHeight = 0
    return notchHeight
  }

  const supportedValue = supportsConstant ? constantValue : envValue
  const div = document.createElement('div')
  div.style.paddingTop = supportedValue
  document.body.appendChild(div)
  const calculatedPadding = parseInt(window.getComputedStyle(div).paddingTop, 10)
  document.body.removeChild(div)
  if (calculatedPadding > 0) {
    notchHeight = calculatedPadding
    return notchHeight
  }
  return 0
}
