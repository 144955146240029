import en from './locales/en/translation'
import et from './locales/et/translation'
import fi from './locales/fi/translation'
import sv from './locales/sv/translation'

export default {
  en: { translation: en },
  et: { translation: et },
  fi: { translation: fi },
  sv: { translation: sv }
}
