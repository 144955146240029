import { Button, Icon } from '@tallink/components-lib'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { changeLanguage, toggleSidebar } from '../../actions/actions'
import { isSidebarOpen, isSsoOpen, isUserLoggedIn } from '../../selectors/selectors'
import AnonymousPanel from './AnonymousPanel/AnonymousPanel'
import s from './Aside.module.scss'
import InfoPanel from './InfoPanel/InfoPanel'
import LoggedInPanel from './LoggedInPanel/LoggedInPanel'

export default function Aside({ hotelCode }) {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const userLoggedIn = useSelector(isUserLoggedIn)

  const asideRef = useRef()
  const sidebarOpen = useSelector(isSidebarOpen)
  const ssoOpen = useSelector(isSsoOpen)
  useEffect(() => {
    if (sidebarOpen && !ssoOpen) {
      if (asideRef?.current) {
        disableBodyScroll(asideRef.current)
      }
    } else {
      clearAllBodyScrollLocks()
    }
  }, [sidebarOpen, asideRef, ssoOpen])

  const handleLanguageChange = language => dispatch(changeLanguage({ language, history }))

  const languageOptions = i18n.options.supportedLngs
    .filter(language => i18n.exists(`selectLanguage.${language}`))
    .map(language => (
      <option key={language} value={language}>
        {t(`selectLanguage.${language}`)}
      </option>
    ))

  const handleOverlayClick = event => {
    if (event.target === event.currentTarget) dispatch(toggleSidebar())
  }

  return (
    <div
      className={classNames(s.overlay, { [s.opened]: sidebarOpen })}
      onClick={handleOverlayClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex="-1"
    >
      <div className={s.aside} ref={asideRef}>
        <div className={s.panels}>
          {userLoggedIn ? <LoggedInPanel /> : <AnonymousPanel hotelCode={hotelCode} />}
          <InfoPanel iconType="globe" isInfo>
            <div className={s.language}>
              <label className={s['language-label']} htmlFor="rightMenu.language">
                {t('rightMenu.language')}:
                <div className={s['select-wrapper']}>
                  <select
                    className={s['select-language']}
                    defaultValue={i18n.language}
                    id="rightMenu.language"
                    onChange={e => handleLanguageChange(e.target.value)}
                  >
                    {languageOptions}
                  </select>
                  <div className={s['change-language']}>
                    <Icon type="pen" />
                    <p className={s.text}>{t('rightMenu.change')}</p>
                  </div>
                </div>
              </label>
            </div>
          </InfoPanel>
        </div>
        <div className={s.footer}>
          <Button fullWidth onClick={() => dispatch(toggleSidebar())} type="text">
            {t('rightMenu.button.close')}
          </Button>
        </div>
      </div>
    </div>
  )
}

Aside.propTypes = {
  hotelCode: PropTypes.string.isRequired
}
