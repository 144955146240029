import PropTypes from 'prop-types'

import { MOBILE_OS } from './BrowserDetector'
import { SHIP_CODES, SHIP_NAMES } from './Ships'
import { reservationChangesTitles } from './Urls'

function componentOfType(componentOrListOfComponents) {
  const supportedListOfComponents = Array.of(componentOrListOfComponents).flat()

  return (props, propName, componentName) => {
    const p = props[propName]

    if (p !== undefined && supportedListOfComponents.every(({ name }) => p.type.name !== name)) {
      const supportedComponentsMessage = supportedListOfComponents.map(({ name }) => `"${name}"`).join(', or ')

      return new Error(
        `Invalid prop "${propName}" supplied to "${componentName}". Expected type of "${supportedComponentsMessage}" but received "${
          p.type.name ? p.type.name : p.type
        }". PropTypes validation failed.`
      )
    }
  }
}

function dateString(props, propName, componentName) {
  const validComponentName = componentName || 'ANONYMOUS'
  const dateString = props[propName]

  if (dateString === '') return null

  if (dateString && typeof dateString === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return null
  }
  return new Error(
    `${propName}: "${dateString}" in ${validComponentName} is not iso date string with format YYYY-MM-DD.`
  )
}

function dateTimeString(props, propName, componentName) {
  const validComponentName = componentName || 'ANONYMOUS'
  const dateString = props[propName]

  if (dateString === '') return null

  if (dateString && typeof dateString === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(dateString)) {
    return null
  }
  return new Error(
    `${propName}: "${dateString}" in ${validComponentName} is not iso date string with format YYYY-MM-DD.`
  )
}

function twoLetters(props, propName, componentName) {
  const validComponentName = componentName || 'ANONYMOUS'
  if (!/[A-Z]{2}/i.test(props[propName])) {
    return new Error(
      `"${props[propName]}" in ${validComponentName} is not a valid ${propName} property (should consist of two letters only).`
    )
  }

  return null
}

const appClocks = PropTypes.shape({
  deviceDateTime: PropTypes.string.isRequired,
  deviceTimeZoneName: PropTypes.string.isRequired,
  shipDateTime: PropTypes.string.isRequired,
  shipTimeZoneName: PropTypes.string.isRequired
}).isRequired

const entertainmentCalendarEvent = PropTypes.shape({
  category: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
  description: PropTypes.string,
  endTime: PropTypes.string,
  isSpecial: PropTypes.bool,
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shipName: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  timeZone: PropTypes.string,
  venueType: PropTypes.string
}).isRequired

const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.number, PropTypes.object, PropTypes.string])
})

const allVehicleCodes = [
  'BICYCLE',
  'CAR ALA OVNGT',
  'CAR XL',
  'CAR',
  'MOTORCYCLE WITH SIDECAR',
  'MOTORCYCLE',
  'PARKED CAR',
  'PARKED CAR 2',
  'SHOPPER CAR',
  'SHOPPER VAN',
  'VAN HIGH',
  'VAN',
  'VEHICLE LONG & HIGH',
  'VEHICLE LONG & LOW',
  'VEHICLE LONG & MEDIUM'
]

const allCityCodes = ['ALA', 'HND', 'HEL', 'RIG', 'STO', 'TAL', 'TUR', 'VIS', 'MUG', 'VSS', 'KAP', 'PARTY']

export default {
  ageCategory: PropTypes.oneOf(['adult', 'child', 'junior', 'youth']),
  appClocks,
  cabinUpgradeTravelClass: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    includesMeal: PropTypes.bool.isRequired,
    longDescription: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    regularPrice: PropTypes.number.isRequired,
    upgradePrice: PropTypes.number.isRequired
  }),
  clubOneLevel: PropTypes.oneOf(['NONE', 'BRONZE', 'SILVER', 'GOLD']),
  changedItem: PropTypes.shape({
    outwards: PropTypes.bool.isRequired,
    returns: PropTypes.bool.isRequired
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  cityCode: PropTypes.oneOf(allCityCodes),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  componentOfType,
  currency: PropTypes.oneOf(['EUR', 'SEK']),
  dateString,
  dateTimeString,
  deviceType: PropTypes.oneOf(['MOBILE', 'TABLET', 'DESKTOP']),
  direction: PropTypes.oneOf(['outward', 'return']),
  entertainmentCalendarEvent,
  landService: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    eventTimes: PropTypes.arrayOf(
      PropTypes.shape({
        dateTime: PropTypes.string,
        endDateTime: PropTypes.string,
        tickets: PropTypes.arrayOf(
          PropTypes.shape({
            ageCategory: PropTypes.string,
            count: PropTypes.number.isRequired,
            name: PropTypes.node,
            price: PropTypes.number.isRequired
          })
        )
      })
    )
  }),
  location,
  mobileOs: PropTypes.oneOf([MOBILE_OS.ANDROID, MOBILE_OS.IOS, MOBILE_OS.UNKNOWN, MOBILE_OS.WINDOWS_PHONE]),
  sail: PropTypes.shape({
    arrivalDateTime: PropTypes.string,
    departureDateTime: PropTypes.string,
    duration: PropTypes.number,
    isOvernight: PropTypes.bool,
    sailId: PropTypes.number
  }),
  sails: PropTypes.shape({
    outwards: PropTypes.object,
    returns: PropTypes.object
  }),
  shipCode: PropTypes.oneOf(SHIP_CODES),
  shipName: PropTypes.oneOf(SHIP_NAMES),
  tooLateForChange: PropTypes.shape({
    outwards: PropTypes.bool.isRequired,
    returns: PropTypes.bool.isRequired
  }),
  transportType: PropTypes.oneOf(['BUS', 'FERRY', 'TAXI', 'TRAM', 'UNDERGROUND', 'WALKING']),
  twoLetters,
  paidAddonType: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  pointsPrice: PropTypes.shape({
    isPointsPriceChanged: PropTypes.bool.isRequired,
    initialPointsPrice: PropTypes.number,
    lastPointsPrice: PropTypes.number
  }),
  reservationChangesBlock: PropTypes.oneOf([
    reservationChangesTitles.departure,
    reservationChangesTitles.onboardServices,
    reservationChangesTitles.landServices,
    reservationChangesTitles.passengers,
    reservationChangesTitles.travelClass,
    reservationChangesTitles.extraServices,
    reservationChangesTitles.vehicles,
    reservationChangesTitles.meals,
    ''
  ]),
  vehicleCodes: PropTypes.oneOf(allVehicleCodes)
}
