import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { ROUTES } from '../../../common/routes'
import { getSummary, hasSelectedProducts as hasSelectedProductsSelector } from '../../../selectors/selectors'
import ButtonWithRouter from '../../common/ButtonWithRouter'
import TotalPrice from '../../TotalPrice/TotalPrice'
import GlobalModal from '../GlobalModal/GlobalModal'
import GlobalToast from '../GlobalToast/GlobalToast'
import BottomNavigation from './BottomNavigation'
import s from './Footer.module.scss'

export default function Footer({ navigationVisible }) {
  const { t } = useTranslation()

  const hasSelectedProducts = useSelector(hasSelectedProductsSelector)
  const { loading: loadingSummary, totalPrice } = useSelector(getSummary)
  const { hotelCode } = useParams()
  const shoppingPageActive = !!useRouteMatch(ROUTES.SHOPPING_PAGE)
  const confirmationPageActive = !!useRouteMatch(ROUTES.CONFIRMATION_PAGE)
  const paymentRejectedPageActive = !!useRouteMatch(ROUTES.PAYMENT_REJECTED_PAGE)

  const checkoutButtonVisible = hasSelectedProducts && !confirmationPageActive && !paymentRejectedPageActive

  return (
    <footer className={s.footer}>
      {checkoutButtonVisible && (
        <>
          {!shoppingPageActive && (
            <div className={s['footer-controls']}>
              <ButtonWithRouter
                className={s['checkout-button']}
                disabled={loadingSummary || !totalPrice}
                fullWidth
                href={generatePath(ROUTES.SHOPPING_PAGE, { hotelCode })}
              >
                {t('order.checkout')}
              </ButtonWithRouter>
            </div>
          )}
          <TotalPrice
            lightTheme={shoppingPageActive}
            navigationVisible={navigationVisible}
            subtractFee={!shoppingPageActive}
          />
        </>
      )}
      <GlobalToast />
      <GlobalModal />
      {navigationVisible && <BottomNavigation />}
    </footer>
  )
}

Footer.propTypes = {
  navigationVisible: PropTypes.bool
}
