import produce from 'immer'

import { DEFAULT_LOCALE } from '../config/config'

const SSO_TOKEN_HEADER_KEY = 'sso-token'
const POST_SUMMARY = 'postSummary'
const requestNameToAbortControllers = {
  [POST_SUMMARY]: []
}

let params = {}
let handleError

function isInitialized() {
  return Object.keys(params).length > 0
}

function init({ locale = DEFAULT_LOCALE, errorHandler, url, paymentServiceUrl, ssoToken }) {
  const paymentServiceApi = `${paymentServiceUrl}/api`
  params = { api: `${url}/api`, paymentServiceApi }
  initLocale(locale)
  if (ssoToken && ssoToken !== params.ssoToken) initSsoToken(ssoToken)
  handleError = errorHandler
}

function initSsoToken(ssoToken) {
  params = { ...params, ssoToken }
}

function initLocale(locale) {
  params = { ...params, locale }
}

function resetSsoToken() {
  params = produce(params, draft => {
    delete draft.ssoToken
  })
}

function getClientParams() {
  return params
}

function reset() {
  params = {}
  handleError = undefined
}

async function fetchWithErrorHandling(resource, requestInit, useSsoHeader = true) {
  const requestInitUpdated =
    params.ssoToken && useSsoHeader
      ? { ...requestInit, headers: { [SSO_TOKEN_HEADER_KEY]: params.ssoToken } }
      : requestInit

  try {
    const response = await fetch(resource, requestInitUpdated)

    if (response.status >= 400) {
      const { errorCode } = await response.json()

      // eslint-disable-next-line no-throw-literal
      throw {
        status: response.status,
        url: response.url,
        errorCode
      }
    }
    return await response.json()
  } catch (error) {
    if (handleError) handleError(error)
    throw error
  }
}

async function postWithErrorHandling({ resource, mode = 'cors', headers = {}, body, signal }) {
  try {
    const response = await fetch(resource, {
      method: 'POST',
      mode,
      signal,
      headers: extendHeadersWithSsoToken(headers),
      body
    })

    // eslint-disable-next-line no-throw-literal
    if (response.status >= 400) {
      const { errorCode } = await response.json()

      // eslint-disable-next-line no-throw-literal
      throw {
        status: response.status,
        url: response.url,
        errorCode
      }
    }
    return await response.json()
  } catch (error) {
    if (handleError) handleError(error)
    throw error
  }
}

async function deleteWithErrorHandling({ resource, mode = 'cors', headers = {}, signal, body, useSsoHeader = true }) {
  try {
    const response = await fetch(resource, {
      method: 'DELETE',
      mode,
      signal,
      headers: useSsoHeader ? extendHeadersWithSsoToken(headers) : headers,
      body
    })

    if (response.status >= 400) {
      const { errorCode } = await response.json()

      // eslint-disable-next-line no-throw-literal
      throw {
        status: response.status,
        url: response.url,
        errorCode
      }
    }
    if (response.status === 204) return Promise.resolve()
    return await response.json()
  } catch (error) {
    if (handleError) handleError(error)
    throw error
  }
}

function getHotelSalePoints(hotelCode) {
  return fetchWithErrorHandling(`${params.api}/hotels/${hotelCode}/sale-points?locale=${params.locale}`)
}

function getStoredPaymentMethods(ssoToken, channelMerchant) {
  return fetchWithErrorHandling(
    `${params.paymentServiceApi}/v1/stored-payment-methods?ssoToken=${ssoToken}&channelMerchant=${channelMerchant}`,
    () => {},
    false
  )
}

function deleteStoredPaymentMethod(storedPaymentMethodId) {
  return deleteWithErrorHandling({
    resource: `${params.paymentServiceApi}/v1/stored-payment-methods/${storedPaymentMethodId}?ssoToken=${params.ssoToken}`,
    useSsoHeader: false
  })
}

function getOrder(orderToken) {
  return fetchWithErrorHandling(`${params.api}/orders/${orderToken}?locale=${params.locale}`)
}

function getSalePointByCodeOrId(salePointCodeOrId) {
  return fetchWithErrorHandling(`${params.api}/sale-points/${salePointCodeOrId}?locale=${params.locale}`)
}

async function getUserProfileBySsoToken(ssoToken) {
  const { bookings, clubOneLevel, email, phone, firstName, lastName } = await fetchWithErrorHandling(
    `${params.api}/customer/profile`,
    {
      headers: { [SSO_TOKEN_HEADER_KEY]: ssoToken }
    }
  )
  return { bookings, clubOneLevel, email, phone, firstName, lastName }
}

async function postSummary(postParams) {
  try {
    const abortController = createAbortController(POST_SUMMARY)
    const response = await fetch(`${params.api}/shopping-carts/?locale=${params.locale}`, {
      method: 'POST',
      mode: 'cors',
      signal: abortController.signal,
      headers: extendHeadersWithSsoToken(),
      body: JSON.stringify(postParams)
    })

    if (response.status >= 400) {
      const { errorCode } = await response.json()

      // eslint-disable-next-line no-throw-literal
      throw {
        status: response.status,
        url: response.url,
        errorCode
      }
    }

    return response.json()
  } catch (ex) {
    if (ex.name === 'AbortError') return
    if (handleError) handleError(ex)

    throw ex
  }
}

function abortPostSummary() {
  abortRequest(POST_SUMMARY)
}

async function createOrder(postParams) {
  return postWithErrorHandling({
    resource: `${params.api}/orders?locale=${params.locale}`,
    body: JSON.stringify(postParams)
  })
}

function getPaymentPageUrl(orderToken, selectedStoredPaymentMethodId) {
  return postWithErrorHandling({
    resource: `${params.api}/orders/${orderToken}/payment?locale=${params.locale}`,
    body: JSON.stringify({ storedPaymentMethodId: selectedStoredPaymentMethodId || null })
  })
}

function createAbortController(requestName) {
  abortRequest(requestName)
  const abortController = new AbortController()
  requestNameToAbortControllers[requestName].push(abortController)
  return abortController
}

function abortRequest(requestName) {
  const latestAbortController = requestNameToAbortControllers[requestName].pop()
  if (latestAbortController) latestAbortController.abort()
}

function extendHeadersWithSsoToken(headers) {
  const { ssoToken } = params
  return {
    'Content-Type': 'application/json; charset=UTF-8',
    ...(ssoToken && { [SSO_TOKEN_HEADER_KEY]: ssoToken }),
    ...headers
  }
}

export default {
  abortPostSummary,
  createOrder,
  getStoredPaymentMethods,
  deleteStoredPaymentMethod,
  getHotelSalePoints,
  getOrder,
  getClientParams,
  getPaymentPageUrl,
  getSalePointByCodeOrId,
  getUserProfileBySsoToken,
  init,
  initLocale,
  initSsoToken,
  isInitialized,
  postSummary,
  reset,
  resetSsoToken
}
