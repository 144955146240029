import { Icon } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './AlcoholRestrictionWarning.module.scss'

export default function AlcoholRestrictionWarning({ hotelCode }) {
  const { t } = useTranslation()

  return (
    <div className={s['alcohol-restriction-warning']}>
      <Icon className={s.icon} color="new-york-pink" type="basket-slash" />
      <p>{t(`services.hotel.${hotelCode}.alcoholSellingRestricted`)}</p>
    </div>
  )
}

AlcoholRestrictionWarning.propTypes = {
  hotelCode: PropTypes.string.isRequired
}
