import { Spinner } from '@tallink/components-lib'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'

import s from './Step.module.scss'
import StepType from './StepType'

export default function Step({
  borderLeftVisible = true,
  borderGradient,
  children,
  comment,
  type,
  heading,
  unavailable,
  loading
}) {
  return (
    <div className={classNames(s.step, { [s.unavailable]: unavailable })}>
      <div className={s['heading-container']}>
        <StepType type={type} unavailable={unavailable} />
        <h2 className={s.heading}>{heading}</h2>
        {loading && <Spinner className={s.spinner} />}
      </div>
      <div
        className={classNames(s.panel, {
          [s['border-left-done-done']]: borderLeftVisible && borderGradient === 'done-done',
          [s['border-left-todo-todo']]: borderLeftVisible && borderGradient === 'todo-todo',
          [s['border-left-todo-done']]: borderLeftVisible && borderGradient === 'todo-done',
          [s['border-left-done-todo']]: borderLeftVisible && borderGradient === 'done-todo'
        })}
      >
        {comment && <p className={s.comment}>{comment}</p>}
        {children}
      </div>
    </div>
  )
}

Step.propTypes = {
  borderGradient: PropTypes.string,
  borderLeftVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  comment: PropTypes.string,
  heading: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['done', 'todo']),
  unavailable: PropTypes.bool
}
