import { createReducer } from '@reduxjs/toolkit'

import {
  postOrderRequest,
  receivedOrderToken,
  receivedProfile,
  receivedStoredPaymentMethods,
  receivedSummary,
  requestSummary,
  resetOrderRequest,
  resetOrderRequestForLoggedInUser,
  setApiError,
  setDeliveryDestinationNumber,
  setDeliveryType,
  setOrderRequest,
  signedOut
} from '../actions/actions'
import { DELIVERY_TYPE_ROOM, PAY_NOW } from '../common/constants'

export const initialState = {
  comment: '',
  deliveryDestinationNumber: '',
  deliveryType: '',
  email: '',
  firstName: '',
  lastName: '',
  loading: false,
  payNowOrLater: PAY_NOW,
  storedPaymentMethodId: ''
}

const orderRequest = createReducer(initialState, {
  [requestSummary]: state => {
    state.loading = true
  },
  [receivedSummary]: state => {
    state.loading = false
  },
  [postOrderRequest]: state => {
    state.loading = true
  },
  [receivedOrderToken]: state => {
    state.loading = false
  },
  [setApiError]: state => {
    state.loading = false
  },
  [setDeliveryDestinationNumber]: (state, { payload }) => {
    state.deliveryDestinationNumber = payload
  },
  [setDeliveryType]: (state, { payload }) => {
    state.deliveryType = payload
  },
  [setOrderRequest]: (state, { payload }) => ({ ...state, ...payload }),
  [receivedProfile]: (state, { payload }) => {
    state.firstName = payload.firstName
    state.lastName = payload.lastName
    state.email = payload.email
    if (
      state.deliveryType === DELIVERY_TYPE_ROOM &&
      !state.deliveryDestinationNumber &&
      payload.bookings !== null &&
      payload.bookings[0]?.roomNumber
    ) {
      state.deliveryDestinationNumber = payload.bookings[0].roomNumber
    }
  },
  [receivedStoredPaymentMethods]: (state, { payload }) => {
    state.storedPaymentMethodId = payload?.[0]?.id
  },
  [resetOrderRequest]: state => ({
    ...initialState,
    deliveryDestinationNumber: state.deliveryDestinationNumber,
    deliveryType: state.deliveryType
  }),
  [resetOrderRequestForLoggedInUser]: state => ({
    ...initialState,
    firstName: state.firstName,
    lastName: state.lastName,
    deliveryDestinationNumber: state.deliveryDestinationNumber,
    deliveryType: state.deliveryType,
    email: state.email,
    storedPaymentMethodId: state.storedPaymentMethodId
  }),
  [signedOut]: state => ({ ...initialState, deliveryType: state.deliveryType })
})

export default orderRequest
