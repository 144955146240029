import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './ServiceAvailability.module.scss'

export default function ServiceAvailability({ closed, unavailable }) {
  const { t } = useTranslation()
  const status = getCurrentStatus()

  return (
    <div className={classNames(s['service-availability'], status === 'open' ? s.open : s.closed)}>
      {t(`services.status.${status}`)}
    </div>
  )

  function getCurrentStatus() {
    if (unavailable) return 'unavailable'
    if (closed) return 'closed'

    return 'open'
  }
}

ServiceAvailability.propTypes = {
  closed: PropTypes.bool.isRequired,
  unavailable: PropTypes.bool.isRequired
}
