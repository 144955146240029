import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { resetOrderRequest } from '../actions/actions'
import { ROUTES } from '../common/routes'
import { isUserLoggedIn } from '../selectors/selectors'

export default function useResetOrderRequest() {
  const dispatch = useDispatch()
  const { location } = useHistory()
  const userIsLoggedIn = useSelector(isUserLoggedIn)

  const prevPathname = usePrevious(location.pathname)

  const shoppingPageMatch = useRouteMatch(ROUTES.SHOPPING_PAGE)

  const leavingServicePage = matchPath(prevPathname, ROUTES.SERVICE_PAGE)
  const leavingSpecialOffersPage = matchPath(prevPathname, ROUTES.SPECIAL_OFFERS_PAGE)
  const leavingConfirmationPage = matchPath(prevPathname, ROUTES.CONFIRMATION_PAGE)

  useEffect(() => {
    if (
      !userIsLoggedIn &&
      !shoppingPageMatch &&
      (leavingServicePage || leavingSpecialOffersPage || leavingConfirmationPage)
    ) {
      dispatch(resetOrderRequest())
    }
  }, [
    dispatch,
    leavingConfirmationPage,
    leavingServicePage,
    leavingSpecialOffersPage,
    shoppingPageMatch,
    userIsLoggedIn
  ])
}
