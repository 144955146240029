const CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT'
const REFUND = 'REFUND'
const SALES_DEPARTMENT = 'SALES_DEPARTMENT'
const HOTEL_RECEPTION = 'HOTEL_RECEPTION'

export const SUPPORT_TYPE = {
  CUSTOMER_SUPPORT,
  HOTEL_RECEPTION,
  REFUND,
  SALES_DEPARTMENT
}
