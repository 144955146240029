import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import Icon from '../Icon/Icon'
import s from './RatingIcons.module.scss'

export default function RatingIcons({ className, rating, iconSize, color = 'default' }) {
  return (
    <span className={classNames(s['rating-icons'], className)}>
      {Array.from({ length: 5 }, (_, index) => (
        <Icon
          className={classNames(s.icon, { [s.inactive]: index >= rating })}
          color={color}
          key={index.toString()}
          size={iconSize}
          type="star"
        />
      ))}
    </span>
  )
}

RatingIcons.propTypes = {
  className: MorePropTypes.className,
  color: PropTypes.oneOf(['default', 'white']),
  iconSize: PropTypes.string,
  rating: PropTypes.number.isRequired
}
