import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import MorePropTypes from '../../common/MorePropTypes'
import s from './FieldText.module.scss'
import FieldTextControlLayout from './FieldTextControlLayout/FieldTextControlLayout'

export default function FieldText({
  className,
  classNameControl,
  disabled,
  errorMessage,
  id,
  inputRef,
  invalid,
  maxLength,
  name,
  onBlur = () => {},
  onChange = () => {},
  onClearClick,
  placeholder,
  showValidMark,
  size,
  value,
  fieldType = 'input',
  inputType = 'text'
}) {
  const [inputValue, setInputValue] = useState(value)

  const handleChange = useCallback(
    event => {
      setInputValue(event.target.value)
      onChange(event)
    },
    [onChange]
  )

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const input =
    fieldType === 'input' ? (
      <input
        className={classNames(
          s.field,
          { [s.clearable]: !!onClearClick, [s.invalid]: invalid, [s.small]: size === 's' },
          className
        )}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={inputRef ? onChange : handleChange}
        placeholder={placeholder}
        ref={inputRef}
        type={inputType}
        value={inputRef ? value : inputValue}
      />
    ) : (
      <TextareaAutosize
        className={s.field}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        value={value}
      />
    )

  return onClearClick || showValidMark ? (
    <FieldTextControlLayout
      className={classNameControl}
      errorMessage={errorMessage}
      invalid={invalid}
      onClearClick={onClearClick}
      showValidMark={showValidMark}
      size={size}
    >
      {input}
    </FieldTextControlLayout>
  ) : (
    input
  )
}

FieldText.propTypes = {
  className: MorePropTypes.className,
  classNameControl: MorePropTypes.className,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  fieldType: PropTypes.oneOf(['input', 'textarea']),
  id: PropTypes.string,
  inputRef: PropTypes.func,
  inputType: PropTypes.oneOf(['text', 'tel']),
  invalid: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClearClick: PropTypes.func,
  placeholder: PropTypes.string,
  showValidMark: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string
}

export function SingleCharacterInput({ invalid, ...rest }) {
  return (
    <input
      autoComplete="off"
      className={classNames(s.field, { [s.invalid]: invalid }, s['single-character-input'])}
      {...rest}
    />
  )
}

SingleCharacterInput.propTypes = {
  invalid: PropTypes.bool
}
