import { yupResolver } from '@hookform/resolvers/yup'
import { Validators } from '@tallink/components-lib'
import * as yup from 'yup'

import { DELIVERY_TYPE_ROOM, PAY_NOW } from '../../common/constants'

const { NAME_VALIDITY_REGEXP, EMAIL_VALIDITY_REGEXP } = Validators()

export function getValidationSchema(t, specialOffersWithoutRoomNumber, deliveryType, wrongDestinationNumber) {
  const getDeliveryDestinationNumberSchema = () => {
    if (deliveryType === DELIVERY_TYPE_ROOM) {
      return yup
        .string()
        .test(
          'isValidDestinationNumberFromBackend',
          t('confirmationOrder.error.roomNumber'),
          value => !wrongDestinationNumber || value !== wrongDestinationNumber
        )
        .required(t('confirmationOrder.error.fieldRequired'))
        .matches(/^\d{3,}$/, { message: t('confirmationOrder.error.roomNumber'), excludeEmptyString: true })
    }

    return yup
      .string()
      .matches(/^\d{1,3}$/, { message: t('confirmationOrder.error.tableNumber'), excludeEmptyString: true })
      .required(t('confirmationOrder.error.fieldRequired'))
      .test(
        'isValidDestinationNumberFromBackend',
        t('confirmationOrder.error.tableNumber'),
        value => !wrongDestinationNumber || value !== wrongDestinationNumber
      )
  }

  const nameSchema = yup.mixed().when('payNowOrLater', {
    is: isPayNow,
    then: yup
      .string()
      .required(t('confirmationOrder.error.fieldRequired'))
      .matches(NAME_VALIDITY_REGEXP, t('confirmationOrder.error.namePattern')),
    otherwise: null
  })

  const emailSchema = yup.mixed().when('payNowOrLater', {
    is: isPayNow,
    then: yup
      .string()
      .required(t('confirmationOrder.error.fieldRequired'))
      .matches(EMAIL_VALIDITY_REGEXP, t('confirmationOrder.error.emailPattern')),
    otherwise: null
  })

  return yupResolver(
    yup.object().shape({
      firstName: nameSchema,
      lastName: nameSchema,
      email: emailSchema,
      deliveryDestinationNumber: specialOffersWithoutRoomNumber ? null : getDeliveryDestinationNumberSchema()
    })
  )
}

function isPayNow(value) {
  return value === PAY_NOW
}
