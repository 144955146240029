import { createReducer } from '@reduxjs/toolkit'

import { clearSummary, receivedSalePoints, receivedSummary, requestSummary, setApiError } from '../actions/actions'
import { findProductById } from '../common/sale-points'
import { getProducts } from '../selectors/selectors'

export const initialState = {
  clubOneDiscount: null,
  deliveryDateTimeSlots: null,
  deliveryFee: null,
  deliveryTimeoutInMinutes: null,
  hasAlcoholProducts: false,
  lastRequestPayload: null,
  loading: false,
  products: [],
  totalPrice: 0
}

const summary = createReducer(initialState, {
  [requestSummary]: (state, { payload }) => {
    state.loading = true
    state.lastRequestPayload = payload
  },
  [setApiError]: state => {
    state.loading = false
  },
  [receivedSummary]: (state, { payload }) => {
    state.clubOneDiscount = payload.clubOneDiscount
    state.deliveryDateTimeSlots = payload.deliveryDateTimeSlots
    state.deliveryFee = payload.deliveryFee
    state.deliveryTimeoutInMinutes = payload.deliveryTimeoutInMinutes
    state.hasAlcoholProducts = payload.hasAlcoholProducts
    state.loading = false
    state.products = payload.products
    state.totalPrice = payload.totalPrice
  },
  [receivedSalePoints]: (state, { payload }) => {
    if (state.products.length === 0) return

    const newProducts = getProducts({ salePoints: payload })
    state.products.forEach(draftSummaryProduct => {
      const newMatchingProduct = findProductById(newProducts, draftSummaryProduct.productId)
      if (newMatchingProduct) {
        draftSummaryProduct.price = newMatchingProduct.price
      }
    })
  },
  [clearSummary]: () => initialState
})

export default summary
