import React from 'react'

import TallinkSiljaBrandLogo from './tallink-silja.svg'
import s from './TallinkSiljaLogo.module.scss'

export default function TallinkSiljaLogo() {
  return (
    <div className={s['tallink-silja-logo']}>
      <img alt="Tallink | Silja Line" src={TallinkSiljaBrandLogo} />
    </div>
  )
}
