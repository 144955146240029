import apiError from './apiError'
import layout from './layout'
import orderInformation from './orderInformation'
import orderRequest from './orderRequest'
import orderToken from './orderToken'
import salePoints from './salePoints'
import sso from './sso'
import summary from './summary'
import user from './user'

export default {
  apiError,
  layout,
  orderInformation,
  orderRequest,
  orderToken,
  salePoints,
  sso,
  summary,
  user
}
