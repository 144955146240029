import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import Icon from '../Icon/Icon'

const levelToIconType = {
  BRONZE: 'club-one-card-bronze',
  GOLD: 'club-one-card-gold',
  SILVER: 'club-one-card-silver'
}

export default function ClubOneCardIcon({ className, level }) {
  return <Icon className={className} size="original" type={levelToIconType[level]} />
}

ClubOneCardIcon.propTypes = {
  className: MorePropTypes.className,
  level: PropTypes.oneOf(Object.keys(levelToIconType)).isRequired
}
