import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import s from './Button.module.scss'

const typeToClass = {
  primary: [s.primary],
  secondary: [s.secondary],
  danger: [s.danger],
  text: [s.text]
}

export default function Button({ className, children, disabled, onClick, type = 'primary', fullWidth, href }) {
  const innerClassName = classNames(s.button, typeToClass[type], fullWidth && s['full-width'], className)

  return disabled || !href ? (
    <button className={innerClassName} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  ) : (
    <a className={innerClassName} href={href} onClick={onClick} role="button">
      {children}
    </a>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: MorePropTypes.className,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(typeToClass))
}
