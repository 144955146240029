import { Button } from '@tallink/components-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router'

const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = event => event.button === 0

export default function ButtonWithRouter({ changeLocation, href, preserveParams = true, ...props }) {
  const history = useHistory()
  const { search, hash } = history.location
  const path = preserveParams ? href + search + hash : href

  function onClick(event) {
    if (changeLocation) {
      window.location = path
    } else {
      if (event.defaultPrevented) return
      if (event.target.getAttribute('target')) return
      if (isModifiedEvent(event) || !isLeftClickEvent(event)) return
      event.preventDefault()
      history.push(path)
    }
  }

  return <Button {...props} href={path} onClick={onClick} />
}

ButtonWithRouter.propTypes = {
  changeLocation: PropTypes.bool,
  href: PropTypes.string.isRequired,
  preserveParams: PropTypes.bool
}
