import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './ServiceDetails.module.scss'

export default function ServiceDetails({ description, email, location, phone, workingSchedule }) {
  const { t } = useTranslation()

  return (
    <div className={s['service-details']}>
      <div className={s.row}>
        {workingSchedule.map(({ date, time }, id) => (
          <div className={s.column} key={id.toString()}>
            <p className={s.title}>{date}</p>
            <p className={s.value}>{time}</p>
          </div>
        ))}
        {location && (
          <div className={s.column}>
            <p className={s.title}>{t('services.salePoint.location')}</p>
            <p className={s.value}>{location}</p>
          </div>
        )}
        <a className={s.column} href={`tel:${phone}`}>
          <p className={s.title}>{t('services.salePoint.phone')}</p>
          <p className={s.value}>{phone}</p>
        </a>
        <a className={s.column} href={`mailto:${email}`}>
          <p className={s.title}>{t('services.salePoint.email')}</p>
          <p className={s.value}>{email}</p>
        </a>
      </div>
      <p className={s['service-description']}>{description}</p>
    </div>
  )
}

ServiceDetails.propTypes = {
  description: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  workingSchedule: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
}
