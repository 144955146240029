import { Icon, Spinner } from '@tallink/components-lib'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'

import s from './InfoPanel.module.scss'

export default function InfoPanel({ className, children, iconType, isInfo, direction, loadingText }) {
  return (
    <div className={s['info-panel']}>
      {iconType && (
        <div className={s.icon}>
          <Icon type={iconType} />
        </div>
      )}
      {!loadingText && (
        <div
          className={classNames(s.panel, className, {
            [s.column]: direction === 'column',
            [s.info]: isInfo
          })}
        >
          {children}
        </div>
      )}
      {loadingText && (
        <div className={s['waiting-panel']}>
          <Spinner className={s.spinner} />
          <p>{loadingText}</p>
        </div>
      )}
    </div>
  )
}

InfoPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.string,
  iconType: PropTypes.string,
  isInfo: PropTypes.bool,
  loadingText: PropTypes.string
}
