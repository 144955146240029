import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import s from './PlusMinus.module.scss'

export default function PlusMinus({
  className,
  label,
  onIncreaseClick,
  onDecreaseClick,
  disabled,
  value = 0,
  maxValue,
  size = 'original',
  type = '',
  withRem
}) {
  return (
    <div
      className={classNames(s['plus-minus'], withRem ? s['with-rem'] : s['with-px'], className, {
        [s.disabled]: disabled,
        [s.initial]: value === 0,
        [s.original]: size === 'original',
        [s.medium]: size === 'm',
        [s.large]: size === 'l',
        [s.tablet]: size === 'tablet',
        [s.classic]: type === 'classic',
        [s.button]: type === 'button'
      })}
    >
      <ButtonIcon
        className={classNames(s['button-icon'], s.minus)}
        disabled={disabled || value === 0}
        iconSize="original"
        iconType="minus-in-circle"
        onClick={onDecreaseClick}
      />
      <span className={classNames(s.value)}>{label && value === 0 ? label : value}</span>
      <ButtonIcon
        className={classNames(s['button-icon'], s.plus, {
          [s['max-value']]: maxValue === value
        })}
        disabled={disabled || maxValue === value}
        iconSize="original"
        iconType="plus-in-circle"
        onClick={onIncreaseClick}
      />
    </div>
  )
}

PlusMinus.propTypes = {
  className: MorePropTypes.className,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxValue: PropTypes.number,
  onDecreaseClick: PropTypes.func.isRequired,
  onIncreaseClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.number,
  withRem: PropTypes.bool
}
