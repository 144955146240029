import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React from 'react'

import s from './InfoRow.module.scss'

export default function InfoRow({ children, asColumn, className }) {
  return <div className={classNames(s['info-row'], className, { [s['as-column']]: asColumn })}>{children}</div>
}

InfoRow.propTypes = {
  asColumn: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}
