import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './i18n/i18n'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'

import App from './App'
import { routeChangeConfirmation } from './common/router'
import createStore from './createStore'
import reportWebVitals from './reportWebVitals'

smoothscroll.polyfill()

const store = createStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router getUserConfirmation={routeChangeConfirmation(store.dispatch, store.getState)}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
