import { Button, ButtonEmpty, Text } from '@tallink/components-lib'
import NativeAdapter from '@tallink/native-adapter'
import Bowser from 'bowser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppPromotionImage from './AppPromotion.jpg'
import s from './AppPromotion.module.scss'

export default function AppPromotion() {
  const { t } = useTranslation()
  const appPromotionVisibleFromSession = sessionStorage.getItem('appPromotionVisible') !== 'false'
  const [appPromotionVisible, setAppPromotionVisible] = useState(appPromotionVisibleFromSession)
  const appStoreLink = createAppStoreLink()
  const hideAppPromotionTemporary = process.env.JEST_WORKER_ID === undefined

  if (hideAppPromotionTemporary || !appPromotionVisible || NativeAdapter.isNative() || !appStoreLink) return null

  return (
    <div className={s['app-promotion']}>
      <div className={s['image-content']}>
        <img alt="" className={s.image} src={AppPromotionImage} />
        <ButtonEmpty className={s.dismiss} onClick={handleDismissClick}>
          {t('appPromotion.button.dismiss')}
        </ButtonEmpty>
      </div>
      <div className={s.content}>
        <Text align="center" className={s.text}>
          {t('appPromotion.promotionText')}
        </Text>
        <Button href={createAppStoreLink()} type="secondary">
          {t('appPromotion.button.download')}
        </Button>
      </div>
    </div>
  )

  function handleDismissClick() {
    setAppPromotionVisible(false)
    sessionStorage.setItem('appPromotionVisible', false)
  }

  function createAppStoreLink() {
    const { name } = Bowser.getParser(global.navigator.userAgent).getOS()
    switch (name) {
      case Bowser.OS_MAP.iOS:
        return 'https://itunes.apple.com/lr/app/tallink-silja-line/id1107519850'
      case Bowser.OS_MAP.Android:
        return 'https://play.google.com/store/apps/details?id=com.tallink.mikiandroid&hl=en'
      default:
        return ''
    }
  }
}
