import { PlusMinus, mediaQueries } from '@tallink/components-lib'
import classNames from 'classnames'
import i18n from 'i18next'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useMedia } from 'react-use'

import { addProduct, removeProduct } from '../../actions/actions'
import { formattedPrice } from '../../common/strings'
import OutOfStockMessage from './OutOfStockMessage'
import s from './Products.module.scss'

const padding = {
  bottom: s.bottom,
  'top-right-bottom-left': s['top-right-bottom-left']
}

export default function Products({
  alcoholOrderingDisabled,
  products,
  innerPadding,
  orderingDisabled,
  withBorderBottom,
  alignImageToCenter,
  categoryAvailable
}) {
  const dispatch = useDispatch()
  const smUp = useMedia(mediaQueries.smUp, true)

  return (
    <div className={s.products}>
      {products.map(({ name: { title, imageUrl, shortDescription }, productId, price, isAvailable, count }) => (
        <div
          className={classNames(s.product, padding[innerPadding], {
            [s.unavailable]: !isAvailable || orderingDisabled || alcoholOrderingDisabled || !categoryAvailable,
            [s['with-border-bottom']]: withBorderBottom
          })}
          key={productId}
        >
          {imageUrl && (
            <img
              alt={title}
              className={classNames(s['product-image'], { [s['align-center']]: alignImageToCenter })}
              onError={event => {
                event.target.style = 'display: none'
              }}
              src={imageUrl}
            />
          )}
          <div className={s['dish-wrapper']}>
            <div className={s.dish}>
              <div className={s['dish-description']}>
                <p className={s.title}>{title}</p>
                {shortDescription && <p className={s.description}>{shortDescription}</p>}
              </div>
              <div className={s['price-and-counter']}>
                <p className={s.price}>{formattedPrice(price, i18n.language)}</p>
                <PlusMinus
                  disabled={orderingDisabled || !categoryAvailable || alcoholOrderingDisabled || !isAvailable}
                  maxValue={99}
                  onDecreaseClick={() => {
                    dispatch(removeProduct(productId))
                  }}
                  onIncreaseClick={() => {
                    dispatch(addProduct(productId))
                  }}
                  size={smUp ? 'tablet' : 'l'}
                  value={count}
                />
              </div>
            </div>
            {(!isAvailable || !categoryAvailable) && !orderingDisabled && <OutOfStockMessage />}
          </div>
        </div>
      ))}
    </div>
  )
}

Products.propTypes = {
  alcoholOrderingDisabled: PropTypes.bool,
  alignImageToCenter: PropTypes.bool,
  categoryAvailable: PropTypes.bool,
  innerPadding: PropTypes.oneOf(Object.keys(padding)),
  orderingDisabled: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.shape({
        title: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        shortDescription: PropTypes.string
      }).isRequired,
      productId: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  withBorderBottom: PropTypes.bool
}
