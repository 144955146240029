import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { clearApiError, removeAllProducts, setPayNowOrLater, updateSalePoint } from '../../actions/actions'
import MirdicClient from '../../clients/MirdicClient'
import {
  PAY_LATER,
  PAY_NOW,
  SALE_POINT_UNAVAILABLE,
  SHOPPING_CART_WARNINGS,
  TOTAL_PRICE_MISMATCH
} from '../../common/constants'
import { ROUTES } from '../../common/routes'
import ConfirmationOrder from '../../components/ConfirmationOrder/ConfirmationOrder'
import { GlobalModalActions } from '../../components/layout/GlobalModal/GlobalModal'
import { GlobalToastActions } from '../../components/layout/GlobalToast/GlobalToast'
import Layout from '../../components/layout/Layout'
import OrderSummary from '../../components/OrderSummary/OrderSummary'
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions'
import useBookingPageInit from '../../hooks/useBookingPageInit'
import useScrollToTop from '../../hooks/useScrollToTop'
import {
  findFirstSalePointWithSelectedProduct,
  getApiError,
  getBackgroundImageUrl,
  getOrderRequest,
  getSelectedProducts,
  getShoppingCartWarnings
} from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './ShoppingPage.module.scss'

export default function ShoppingPage() {
  const { loading } = useBookingPageInit()

  return loading ? <LoadingPage /> : <ShoppingPageLoaded />
}

function ShoppingPageLoaded() {
  useScrollToTop()
  const { t } = useTranslation()
  const [toastVisible, setToastVisible] = useState(false)

  const selectedProducts = useSelector(getSelectedProducts)

  const dispatch = useDispatch()
  const { hotelCode } = useParams()
  const { payNowOrLater } = useSelector(getOrderRequest)
  const salePoint = useSelector(findFirstSalePointWithSelectedProduct)
  const error = useSelector(getApiError)
  const backgroundImageUrl = useSelector(getBackgroundImageUrl)
  const payLaterAvailable = salePoint?.isPayLaterAvailable
  const minutesBeforeClosingAlcoholOrdering = salePoint?.minutesBeforeClosing?.alcoholic

  const shoppingCartWarnings = useSelector(getShoppingCartWarnings)

  const handleOnClose = useCallback(async () => {
    const chosenSalePoint = await MirdicClient.getSalePointByCodeOrId(salePoint.salePointCode)

    dispatch(updateSalePoint(chosenSalePoint))
    dispatch(clearApiError())
    setToastVisible(false)
  }, [dispatch, salePoint])

  useEffect(() => {
    if (!payLaterAvailable && payNowOrLater === PAY_LATER) {
      dispatch(setPayNowOrLater(PAY_NOW))
    }
  }, [payLaterAvailable, dispatch, payNowOrLater])

  useEffect(() => {
    const closeButtonText = t('salePointPage.toast.dismiss')

    if (shoppingCartWarnings.includes(SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_ZERO_QUANTITY)) {
      GlobalToastActions.show({
        children: t('shoppingPage.toast.itemRemoved'),
        type: 'warning',
        closeButtonText,
        onClose: () => setToastVisible(false),
        onOpen: () => setToastVisible(true)
      })
    }

    if (
      shoppingCartWarnings.includes(SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_PRICE_CHANGE) ||
      error?.errorCode === TOTAL_PRICE_MISMATCH
    ) {
      GlobalToastActions.show({
        children: t('shoppingPage.toast.priceChanged'),
        type: 'primary',
        closeButtonText,
        onClose: handleOnClose,
        onOpen: () => setToastVisible(true)
      })
    }

    if (shoppingCartWarnings.includes(SHOPPING_CART_WARNINGS.HAS_PRODUCT_WITH_ALCOHOL_RESTRICTION)) {
      GlobalToastActions.show({
        children: t('salePointPage.toast.alcoholOrderingClosing', {
          minutesLeft: minutesBeforeClosingAlcoholOrdering
        }),
        type: 'primary',
        closeButtonText,
        onClose: () => setToastVisible(false),
        onOpen: () => setToastVisible(true)
      })
    }

    if (error?.errorCode === SALE_POINT_UNAVAILABLE) {
      GlobalToastActions.show({
        children: t('services.status.unavailable'),
        type: 'warning',
        closeButtonText: t('salePointPage.toast.back'),
        onClose: () => {
          dispatch(removeAllProducts())
          dispatch(clearApiError())
          setToastVisible(false)
        },
        onOpen: () => setToastVisible(true)
      })
    }
  }, [dispatch, error, handleOnClose, minutesBeforeClosingAlcoholOrdering, shoppingCartWarnings, t])

  const history = useHistory()
  if (selectedProducts.length === 0) {
    const servicesPageUrl = generatePath(ROUTES.SERVICES_PAGE, { hotelCode })
    history.push(servicesPageUrl + history.location.search + history.location.hash)
    return null
  }

  return (
    <Layout
      backgroundImageUrl={backgroundImageUrl}
      headerVisible={false}
      navigationVisible={false}
      pageTitle={t('page.shopping.title')}
    >
      <div className={s['shopping-page']}>
        <OrderSummary />
        <div className={s['vertical-line']} />
        <ConfirmationOrder
          handleOpenPopup={() => {
            GlobalModalActions.show({
              children: <TermsAndConditions handleCloseButtonClick={() => GlobalModalActions.hide()} />,
              asView: true
            })
          }}
          toastVisible={toastVisible}
        />
      </div>
    </Layout>
  )
}
