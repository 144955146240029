import {
  Button,
  ButtonEmpty,
  FieldText,
  FormRow,
  Icon,
  RadioSwitcherAsBlocks,
  Select,
  Spacer,
  Spinner,
  mediaQueries
} from '@tallink/components-lib'
import { useQuery } from '@tallink/more-react-hooks'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import { useMedia, useUpdateEffect } from 'react-use'

import { clearApiError, createOrder, setDeliveryType, setOrderRequest } from '../../actions/actions'
import borderGradient from '../../common/border-gradient'
import {
  DELIVERY_TYPE_ROOM,
  DELIVERY_TYPE_TABLE,
  HOTEL_ROOM_NOT_FOUND,
  MAX_COMMENT_LENGTH,
  PAY_LATER,
  PAY_NOW,
  SALE_POINT_TABLE_NOT_FOUND
} from '../../common/constants'
import { isSpecialOffersSalePoint } from '../../common/sale-points'
import {
  findFirstSalePointWithSelectedProduct,
  getApiError,
  getOrderRequest,
  getRoomNumberFromProfile,
  getStoredPaymentMethods,
  getSummary,
  isUserLoggedIn,
  isUserProfileLoaded
} from '../../selectors/selectors'
import SsoSignInButton from '../common/SsoSignInButton'
import s from './ConfirmationOrder.module.scss'
import { getValidationSchema } from './ConfirmationOrder.schema'
import Step from './Step/Step'

export default function ConfirmationOrder({ handleOpenPopup, toastVisible }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    deliveryType,
    deliveryDestinationNumber,
    payNowOrLater,
    firstName,
    lastName,
    loading: loadingOrder,
    email,
    phone,
    storedPaymentMethodId,
    comment
  } = useSelector(getOrderRequest)

  const [wrongDestinationNumber, setWrongDestinationNumber] = useState('')

  const { deliveryTimeoutInMinutes, deliveryDateTimeSlots } = useSelector(getSummary)
  const salePoint = useSelector(findFirstSalePointWithSelectedProduct)
  const storedPaymentMethods = useSelector(getStoredPaymentMethods)
  const userLoggedIn = useSelector(isUserLoggedIn)
  const userProfileLoaded = useSelector(isUserProfileLoaded)
  const roomNumber = useSelector(getRoomNumberFromProfile)
  const smUp = useMedia(mediaQueries.smUp)
  const kiosk = useQuery('mode') === 'kiosk'

  const defaultValues = {
    deliveryType,
    deliveryDestinationNumber,
    firstName,
    lastName,
    email,
    phone,
    payNowOrLater,
    storedPaymentMethodId,
    comment
  }

  const apiError = useSelector(getApiError)

  const specialOffersWithoutRoomNumber = isSpecialOffersSalePoint(salePoint) && !roomNumber
  const { isPayLaterAvailable, isRoomDeliveryAvailable, isTableDeliveryAvailable } = salePoint

  const { handleSubmit, formState, setError, setValue, getValues, control, watch, trigger, clearErrors } = useForm({
    mode: 'all',
    defaultValues,
    resolver: getValidationSchema(t, specialOffersWithoutRoomNumber, deliveryType, wrongDestinationNumber)
  })

  const isDeliveryDestinationNumberValid = () => {
    if (!roomNumber && isSpecialOffersSalePoint(salePoint)) return true
    if (!formState.errors.deliveryDestinationNumber && getValues('deliveryDestinationNumber')) return true
  }

  const nameInputDisabled = !isDeliveryDestinationNumberValid() || userLoggedIn

  const watchPayNowOrLater = watch('payNowOrLater')

  const isContactInfoValid = () => {
    if (
      !formState.errors.email &&
      !formState.errors.firstName &&
      !formState.errors.lastName &&
      getValues('firstName') &&
      getValues('lastName') &&
      getValues('email')
    ) {
      return true
    }
  }

  useEffect(() => {
    if (userLoggedIn) {
      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('email', email)
      setValue('comment', comment)
    }
  }, [comment, email, firstName, lastName, setValue, userLoggedIn])

  useEffect(() => {
    if (!deliveryDateTimeSlots?.length && deliveryTimeoutInMinutes) {
      setValue('deliveryDateTime', '')
    }

    if (deliveryDateTimeSlots?.length && !deliveryTimeoutInMinutes) {
      setValue('deliveryDateTime', deliveryDateTimeSlots[0])
    }
  }, [deliveryDateTimeSlots, deliveryTimeoutInMinutes, setValue])

  useEffect(() => {
    if ([HOTEL_ROOM_NOT_FOUND, SALE_POINT_TABLE_NOT_FOUND].includes(apiError?.errorCode)) {
      setWrongDestinationNumber(getValues('deliveryDestinationNumber'))
      setError(
        'deliveryDestinationNumber',
        {
          message:
            apiError.errorCode === HOTEL_ROOM_NOT_FOUND
              ? t('confirmationOrder.error.roomNumber')
              : t('confirmationOrder.error.tableNumber')
        },
        { shouldFocus: true }
      )
    }
  }, [apiError, getValues, setError, t])

  useEffect(() => {
    ;(async () => {
      setValue('deliveryType', deliveryType)
      if (isRoomDeliveryType(deliveryType)) setValue('payNowOrLater', PAY_NOW)

      if (deliveryDestinationNumber) {
        setValue('deliveryDestinationNumber', deliveryDestinationNumber)
        await trigger('deliveryDestinationNumber')
      }
    })()
  }, [deliveryDestinationNumber, deliveryType, getValues, setValue, trigger])

  useUpdateEffect(() => {
    ;(async () => {
      if (isRoomDeliveryType(deliveryType) && roomNumber) {
        setValue('deliveryDestinationNumber', roomNumber)
      } else {
        setValue('deliveryDestinationNumber', '')
      }

      await trigger('deliveryDestinationNumber')
      clearErrors('deliveryDestinationNumber')
    })()
  }, [roomNumber, setValue, deliveryType])

  useEffect(() => {
    if (watchPayNowOrLater === PAY_NOW) setValue('storedPaymentMethodId', storedPaymentMethodId)
    if (watchPayNowOrLater === PAY_LATER) setValue('storedPaymentMethodId', '')
  }, [setValue, storedPaymentMethodId, watchPayNowOrLater])

  useEffect(() => {
    const subscription = watch(values => dispatch(setOrderRequest(values)))

    return () => subscription.unsubscribe()
  }, [dispatch, watch])

  const handleClear = name => {
    setValue(name, '', { shouldValidate: true })
    dispatch(clearApiError())
  }

  const { getBorderGradient, getStepStatus } = borderGradient({
    userLoggedIn,
    deliveryDestinationNumberValid: isDeliveryDestinationNumberValid(),
    watchPayNowOrLater,
    contactInfoValid: isContactInfoValid(),
    payLaterAvailable: isPayLaterAvailable,
    payNowOrLater,
    storedPaymentMethods,
    deliveryType
  })

  const handleDeliveryTypeChange = useCallback(
    selectedDeliveryType => dispatch(setDeliveryType(selectedDeliveryType)),
    [dispatch]
  )

  return (
    <div
      className={classNames(s['confirmation-order'], {
        [s['with-toast']]: toastVisible
      })}
    >
      {!userLoggedIn && (
        <Step
          borderGradient={getBorderGradient('signIn')}
          comment={t('confirmationOrder.clubOneBenefits')}
          heading={t('common.signIn')}
          type={getStepStatus('signIn')}
        >
          <SsoSignInButton className={s['sso-button']} />
        </Step>
      )}
      <Step
        borderGradient={getBorderGradient('deliveryDestinationNumber')}
        comment={getDeliveryTypeComment()}
        heading={t('confirmationOrder.delivery.heading')}
        type={getStepStatus('deliveryDestinationNumber')}
      >
        {isTableDeliveryAvailable && isRoomDeliveryAvailable && !kiosk && (
          <Controller
            control={control}
            name="deliveryType"
            render={({ field: { name } }) => (
              <>
                <RadioSwitcherAsBlocks
                  disabled={false}
                  inColumn={!smUp}
                  name={name}
                  onChange={handleDeliveryTypeChange}
                  optional={false}
                  options={[
                    { value: DELIVERY_TYPE_ROOM, text: t('confirmationOrder.chooseDeliveryType.room') },
                    { value: DELIVERY_TYPE_TABLE, text: t('confirmationOrder.chooseDeliveryType.table') }
                  ]}
                  value={deliveryType}
                />
                <Spacer size="s" />
              </>
            )}
          />
        )}
        {!specialOffersWithoutRoomNumber && (
          <Controller
            control={control}
            name="deliveryDestinationNumber"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
              <FormRow
                id="deliveryDestinationNumber"
                label={
                  isRoomDeliveryType(deliveryType) ? t('confirmationOrder.roomNumber') : t('shoppingPage.tableNumber')
                }
              >
                <FieldText
                  classNameControl={s['field-text']}
                  errorMessage={error?.message}
                  inputRef={ref}
                  inputType="tel"
                  invalid={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  onClearClick={() => handleClear(name)}
                  showValidMark={!!value && !invalid}
                  value={value}
                />
              </FormRow>
            )}
          />
        )}
        {deliveryDateTimeSlots && (
          <Controller
            control={control}
            name="deliveryDateTime"
            render={({ field: { onChange, value } }) => (
              <FormRow id="deliveryDateTime" label={t('confirmationOrder.delivery.chooseTimeSlot')}>
                <Select
                  className={s['select-delivery-date-time']}
                  onChange={onChange}
                  options={deliveryDateTimeSlots.map(slot => ({
                    value: slot,
                    text: format(parseISO(slot), 'H:mm')
                  }))}
                  value={value}
                />
              </FormRow>
            )}
          />
        )}
      </Step>
      {watchPayNowOrLater !== PAY_LATER && (
        <Step
          borderGradient={getBorderGradient('contactInfo')}
          heading={t('confirmationOrder.contactInfo')}
          loading={userLoggedIn && !userProfileLoaded}
          type={getStepStatus('contactInfo')}
          unavailable={!isDeliveryDestinationNumberValid()}
        >
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
              <FormRow id="firstName" label={t('confirmationOrder.firstName')}>
                <FieldText
                  classNameControl={s['field-text']}
                  disabled={nameInputDisabled}
                  errorMessage={error?.message}
                  inputRef={ref}
                  invalid={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  onClearClick={() => handleClear(name)}
                  showValidMark={!!value && !invalid}
                  value={value}
                />
              </FormRow>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
              <FormRow id="lastName" label={t('confirmationOrder.lastName')}>
                <FieldText
                  classNameControl={s['field-text']}
                  disabled={nameInputDisabled}
                  errorMessage={error?.message}
                  inputRef={ref}
                  invalid={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  onClearClick={() => handleClear(name)}
                  showValidMark={!!value && !invalid}
                  value={value}
                />
              </FormRow>
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
              <FormRow id="email" label={t('confirmationOrder.email')}>
                <FieldText
                  classNameControl={s['field-text']}
                  disabled={!isDeliveryDestinationNumberValid()}
                  errorMessage={error?.message}
                  inputRef={ref}
                  invalid={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  onClearClick={() => handleClear(name)}
                  showValidMark={!!value && !invalid}
                  value={value}
                />
              </FormRow>
            )}
          />
          <div className={s.notice}>
            <Icon className={s['notice-icon']} color="viking" type="exclamation-circle-fill" />
            <p>{t('confirmationOrder.confirmationAndReceipt')}</p>
          </div>
        </Step>
      )}
      {isPayLaterAvailable && deliveryType === DELIVERY_TYPE_TABLE && (
        <Step
          borderGradient={getBorderGradient('payNowOrLater')}
          comment={t('confirmationOrder.payLater.description')}
          heading={t('confirmationOrder.payLater.heading')}
          type={getStepStatus('payNowOrLater')}
        >
          <Controller
            control={control}
            name="payNowOrLater"
            render={({ field: { onChange, value, name } }) => (
              <RadioSwitcherAsBlocks
                disabled={false}
                inColumn={!smUp}
                name={name}
                onChange={onChange}
                optional={false}
                options={[
                  { value: PAY_NOW, text: t('confirmationOrder.payLater.options.payNow') },
                  { value: PAY_LATER, text: t('confirmationOrder.payLater.options.payLater') }
                ]}
                value={value}
              />
            )}
          />
        </Step>
      )}
      <Step
        borderGradient={getBorderGradient('comments')}
        borderLeftVisible={watchPayNowOrLater === PAY_NOW && storedPaymentMethods.length > 0}
        comment={t('confirmationOrder.info.specialRequest')}
        heading={t('confirmationOrder.comments')}
        type={getStepStatus('comments')}
        unavailable={!isDeliveryDestinationNumberValid()}
      >
        <Controller
          control={control}
          name="comment"
          render={({ field: { name, onChange, value } }) => (
            <FormRow
              id="comment"
              label={t('confirmationOrder.comments')}
              optionalLabel={
                comment.length
                  ? t(`confirmationOrder.comments.charactersLeft`, {
                      charactersLeft: MAX_COMMENT_LENGTH - comment.length
                    })
                  : t('confirmationOrder.optional')
              }
            >
              <FieldText
                disabled={!isDeliveryDestinationNumberValid()}
                fieldType="textarea"
                maxLength={MAX_COMMENT_LENGTH}
                name={name}
                onChange={onChange}
                value={value}
              />
            </FormRow>
          )}
        />
      </Step>
      {watchPayNowOrLater === PAY_NOW && storedPaymentMethods.length > 0 && (
        <Step
          borderGradient={getBorderGradient('paymentMethod')}
          heading={t('confirmationOrder.paymentMethod')}
          unavailable={!isDeliveryDestinationNumberValid()}
        >
          <Controller
            control={control}
            name="storedPaymentMethodId"
            render={({ field: { onChange, value } }) => (
              <FormRow id="storedPaymentMethodId" label={t('confirmationOrder.choosePaymentMethod')}>
                <Select
                  className={s['select-stored-payment-method']}
                  disabled={!isDeliveryDestinationNumberValid()}
                  onChange={onChange}
                  options={storedPaymentMethods
                    .map(({ id, value }) => ({ value: id, text: value }))
                    .concat({ value: '', text: t('selectPayment.otherMethod') })}
                  value={value}
                />
              </FormRow>
            )}
          />
        </Step>
      )}
      <div className={s['terms-and-conditions']}>
        <p>
          {reactStringReplace(t('confirmationOrder.termsAndConditions.text'), '#TAC_LINK#', index => (
            <ButtonEmpty className={s['terms-and-conditions-button']} key={index} onClick={() => handleOpenPopup()}>
              {t('confirmationOrder.termsAndConditions.link')}
            </ButtonEmpty>
          ))}
        </p>
      </div>
      <Button
        className={s['create-order-button']}
        disabled={loadingOrder || !formState.isValid}
        fullWidth={!smUp}
        onClick={handleSubmit(onSubmit)}
      >
        {loadingOrder ? <Spinner className={s.spinner} /> : getCreateOrderButtonText()}
      </Button>
    </div>
  )

  function onSubmit(data, e) {
    e.preventDefault()
    const orderRequest = data.payNowOrLater === PAY_LATER ? { ...data, email: '' } : data

    dispatch(setOrderRequest(orderRequest))
    dispatch(createOrder())
  }

  function getCreateOrderButtonText() {
    return isPayLaterAvailable && watchPayNowOrLater === PAY_LATER
      ? t('shoppingPage.button.confirmAndOrder')
      : t('shoppingPage.button.confirmAndPay')
  }

  function getDeliveryTypeComment() {
    const minutes = t('deliveryTimeoutInMinutes.value')
    if (specialOffersWithoutRoomNumber) return t('confirmationOrder.delivery.toReception', { minutes })
    if (deliveryType === DELIVERY_TYPE_TABLE) return t('confirmationOrder.delivery.toTable')
    if (deliveryTimeoutInMinutes) return t('confirmationOrder.delivery.timeToRoom', { minutes })
    return t('confirmationOrder.delivery.toRoom')
  }
}

function isRoomDeliveryType(value) {
  return value === DELIVERY_TYPE_ROOM
}

ConfirmationOrder.propTypes = {
  handleOpenPopup: PropTypes.func.isRequired,
  toastVisible: PropTypes.bool
}
