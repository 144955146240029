import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import Icon from '../Icon/Icon'
import s from './PanelHeader.module.scss'

const PanelHeader = forwardRef(
  (
    { className, linkText = '', mobile, onEditClick, title, arrowLeftVisible, arrowRightVisible, withUnderline },
    ref
  ) => {
    const hasClickHandler = typeof onEditClick === 'function'
    const editLinkVisible = hasClickHandler && (linkText || arrowLeftVisible || arrowRightVisible)
    const mainClass = classNames(s['panel-header'], className, {
      [s['with-edit-link']]: editLinkVisible,
      [s['with-underline']]: withUnderline,
      [s.mobile]: mobile
    })

    return (
      <header className={mainClass} display-if={title || editLinkVisible} ref={ref}>
        <div className={s.title} display-if={title}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <button className={s['edit-link']} display-if={editLinkVisible} onClick={onEditClick}>
          <Icon className={s['arrow-left']} color="buoy" display-if={arrowLeftVisible} type="arrow-left" />
          <div className={s['edit-link-text']} display-if={linkText}>
            {linkText}
          </div>
          <Icon className={s['arrow-right']} display-if={arrowRightVisible} type="arrow-right" />
        </button>
      </header>
    )
  }
)

PanelHeader.propTypes = {
  arrowLeftVisible: PropTypes.bool,
  arrowRightVisible: PropTypes.bool,
  className: PropTypes.string,
  linkText: PropTypes.string,
  mobile: PropTypes.bool,
  onEditClick: PropTypes.func,
  title: PropTypes.string,
  withUnderline: PropTypes.bool
}

PanelHeader.displayName = 'PanelHeader'

export default PanelHeader
