import { BackButton, ButtonIcon, mediaQueries } from '@tallink/components-lib'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useParams, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useMedia } from 'react-use'

import { toggleSidebar } from '../../../actions/actions'
import { ROUTES } from '../../../common/routes'
import s from './Header.module.scss'

export default function Header({ backButtonVisible, title, sidebarToggleButtonVisible = true }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { hotelCode } = useParams()
  const smUp = useMedia(mediaQueries.smUp)
  const serviceMatchPage = useRouteMatch(ROUTES.SERVICE_PAGE)

  const handleBackButtonClick = () => {
    const { search, hash } = history.location
    const path = generatePath(ROUTES.SERVICES_PAGE, { hotelCode }) + search + hash
    history.push(path)
  }

  const showClock = !serviceMatchPage && smUp

  return (
    <header aria-level="1" className={s.header} role="heading">
      {showClock ? (
        <HeaderWithTime title={title} />
      ) : (
        <>
          <div className="left-side">
            {backButtonVisible && <BackButton color="white" onClick={() => handleBackButtonClick()} />}
          </div>
          <div className="center-part">{title}</div>
        </>
      )}
      <div className={s['right-side']}>
        {sidebarToggleButtonVisible && (
          <ButtonIcon
            color="white"
            iconSize={smUp ? 'original' : 'm'}
            iconType="three-dots-vertical"
            onClick={() => dispatch(toggleSidebar())}
          />
        )}
      </div>
    </header>
  )
}

function HeaderWithTime({ title }) {
  const [currentTime, setCurrentTime] = useState(format(new Date(), 'HH:mm'))

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(format(new Date(), 'HH:mm')), 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className="left-side">{title}</div>
      <div className="center-part">{currentTime}</div>
    </>
  )
}

HeaderWithTime.propTypes = {
  title: PropTypes.string.isRequired
}

export const headerPropTypes = {
  backButtonVisible: PropTypes.bool,
  sidebarToggleButtonVisible: PropTypes.bool,
  title: PropTypes.string
}

Header.propTypes = headerPropTypes
