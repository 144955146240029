/* eslint-disable import/no-extraneous-dependencies */
import { useScript } from '@tallink/more-react-hooks'
import NativeAdapter from '@tallink/native-adapter'
import { useEffectOnce } from 'react-use'

const ENV_TO_SCRIPT_URL = {
  local: `https://dev-cms-web-consent.fleet.zone/bundle.development.min.js?t=${Date.now()}`,
  dev: `https://dev-cms-web-consent.fleet.zone/bundle.development.min.js?t=${Date.now()}`,
  test: `https://test-cms-web-consent.fleet.zone/bundle.test.min.js?t=${Date.now()}`,
  prod: `https://consent.tallink.com/bundle.production.min.js?t=${Date.now()}`
}

const LANGUAGE_TO_SUPPORTED_COUNTRIES = {
  de: ['de'],
  en: ['xz'],
  et: ['ee'],
  fi: ['fi'],
  lt: ['lv'],
  lv: ['lv'],
  ru: ['ee', 'lv', 'ru'],
  sv: ['dk', 'fi', 'no', 'se']
}

export default function useCookieConsent({ country, disabled = false, env, hostEnv, language }) {
  const cookieConsentSettings = {
    env,
    link: false,
    locale: getLocale(country, language),
    tab: ''
  }
  const trackingAlreadyAllowed = NativeAdapter.getIsTrackingAllowed()
  const url = ENV_TO_SCRIPT_URL[hostEnv]

  useScript(url)

  useEffectOnce(() => {
    if (disabled || window.location.href.includes('CYPRESS_RUNNING')) return
    window.addEventListener('cms-web-consent-root-loaded', () => {
      if (typeof trackingAlreadyAllowed === 'boolean') window.CWC.setCookie(trackingAlreadyAllowed)
      else window.CWC.render(cookieConsentSettings)
    })
  })
}

const getLocale = (country, language) => {
  const supportedCountries = LANGUAGE_TO_SUPPORTED_COUNTRIES[language]

  if (supportedCountries) {
    return supportedCountries.includes(country?.toLowerCase())
      ? `${language}-${country?.toLowerCase()}`
      : `${language}-${supportedCountries[0]}`
  }

  return 'en-xz'
}
