import { Icon, mediaQueries } from '@tallink/components-lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'

import s from './OutOfStockMessage.module.scss'

export default function OutOfStockMessage() {
  const smUp = useMedia(mediaQueries.smUp, true)
  const { t } = useTranslation()

  return (
    <div className={s['out-of-stock-message']}>
      <Icon color="viking" size={smUp ? 'l' : 'm'} type="exclamation-circle-fill" />
      <p>{t('salePointPage.itemOutOfStock')}</p>
    </div>
  )
}
