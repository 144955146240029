import { Accordion, Icon } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'

import Products from '../Products/Products'
import AlcoholRestrictionWarning from './AlcoholRestrictionWarning'
import s from './Categories.module.scss'

export default function Categories({ alcoholOrderingDisabled, orderingDisabled, categories, hotelCode }) {
  const openedCategoryId = categories[0].categoryId
  const isAlcoholOrderingRestricted = category =>
    category.isAlcoholic && category.isAvailable && alcoholOrderingDisabled

  return (
    <div className={s.categories}>
      {categories
        .map(category => ({ ...category, alcoholOrderingRestricted: isAlcoholOrderingRestricted(category) }))
        .map(
          ({
            name: { title },
            products,
            categoryId,
            alcoholOrderingRestricted,
            isAvailable: categoryAvailable,
            isAlcoholic
          }) => (
            <Accordion
              className={s.accordion}
              iconSize="m"
              initiallyOpen={categoryId === openedCategoryId}
              key={categoryId}
              titleAlign="left"
              titleContent={
                <CategoryTitle
                  alcoholOrderingRestricted={alcoholOrderingRestricted && !orderingDisabled}
                  title={title}
                />
              }
            >
              {alcoholOrderingRestricted && !orderingDisabled && <AlcoholRestrictionWarning hotelCode={hotelCode} />}
              <Products
                alcoholOrderingDisabled={alcoholOrderingDisabled && isAlcoholic}
                alignImageToCenter
                categoryAvailable={categoryAvailable}
                innerPadding="bottom"
                orderingDisabled={orderingDisabled}
                products={products}
                withBorderBottom
              />
            </Accordion>
          )
        )}
    </div>
  )
}

Categories.propTypes = {
  alcoholOrderingDisabled: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      name: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired,
      products: PropTypes.array.isRequired,
      reason: PropTypes.string
    }).isRequired
  ).isRequired,
  hotelCode: PropTypes.string.isRequired,
  orderingDisabled: PropTypes.bool.isRequired
}

function CategoryTitle({ alcoholOrderingRestricted, title }) {
  return (
    <div className={s['category-title']}>
      <p>{title}</p>
      {alcoholOrderingRestricted && <Icon className={s.icon} color="light-slate-gray" size="l" type="basket-slash" />}
    </div>
  )
}

CategoryTitle.propTypes = {
  alcoholOrderingRestricted: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}
