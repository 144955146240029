import { ClubOneCardIcon, Rating } from '@tallink/components-lib'
import { useQuery } from '@tallink/more-react-hooks'
import { format } from 'date-fns'
import { enUS, et, fi, ru, sv } from 'date-fns/locale'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { handleDeleteStoredPaymentMethod } from '../../../actions/actions'
import { getFirstBooking, getStoredPaymentMethods, getUser, isUserProfileLoaded } from '../../../selectors/selectors'
import InfoPanel from '../InfoPanel/InfoPanel'
import InfoRow from '../InfoRow/InfoRow'
import s from './LoggedInPanel.module.scss'
import TwoStepButton from './TwoStepButton'

const dateLocales = {
  en: enUS,
  et,
  fi,
  ru,
  sv
}

export default function LoggedInPanel() {
  const { t } = useTranslation()
  const { firstName, lastName, clubOneLevel } = useSelector(getUser)
  const userProfileLoaded = useSelector(isUserProfileLoaded)
  const storedPaymentMethods = useSelector(getStoredPaymentMethods)
  const booking = useSelector(getFirstBooking)
  const dateLocaleConfig = { locale: dateLocales[useQuery('locale')] || dateLocales.en }
  const dispatch = useDispatch()

  return (
    <div className={s['logged-in-panel']}>
      {!userProfileLoaded && <InfoPanel loadingText={t('sidebar.waiting.title')} />}
      {userProfileLoaded && (
        <>
          <InfoPanel iconType="adult" isInfo>
            <div className={s['personal-club-one-card']}>
              <p className={s['medium-text']}>
                {firstName} {lastName}
              </p>
              {userProfileLoaded && clubOneLevel !== 'NONE' && (
                <ClubOneCardIcon className={s['club-one-card-icon']} level={clubOneLevel} />
              )}
            </div>
            <TwoStepButton
              buttonColor="caribbean-green"
              iconColor="buoy"
              iconType="sign-out"
              onConfirmClick={() => window.SSO.signOut()}
              text={t('common.signOut')}
              textColor="buoy"
            />
          </InfoPanel>
          {userProfileLoaded && booking && (
            <InfoPanel direction="column" iconType="bed" isInfo>
              <Rating
                direction="column"
                iconSize="s"
                name={booking.hotelName}
                nameSize="m"
                rating={booking.hotelRating}
              />
              <hr className={s.rule} />
              {booking.roomNumber && (
                <InfoRow>
                  <p>{t('rightMenu.room')}:</p>
                  <p className={s['medium-text']}>{booking.roomNumber}</p>
                </InfoRow>
              )}
              <InfoRow>
                <p>{t('rightMenu.check-in')}:</p>
                <p className={s['medium-text']}>
                  {format(new Date(booking.hotelCheckInDateTime), `${t('date.mediumDateWithYear')}`, dateLocaleConfig)}
                </p>
              </InfoRow>
              <InfoRow>
                <p>{t('rightMenu.check-out')}:</p>
                <p className={s['medium-text']}>
                  {format(new Date(booking.hotelCheckOutDateTime), `${t('date.mediumDateWithYear')}`, dateLocaleConfig)}
                </p>
              </InfoRow>
            </InfoPanel>
          )}
          {storedPaymentMethods.length > 0 && (
            <InfoPanel className={s['cards-panel']} direction="column" iconType="card">
              {storedPaymentMethods.map(({ name, value, id }) => (
                <InfoRow asColumn className={s.row} key={value}>
                  <div className={s['payment-wrapper']}>
                    <div className={s.payment}>
                      <p>{name}:</p>
                      <p className={s['medium-text']}>{value}</p>
                    </div>
                    <TwoStepButton
                      buttonColor="new-york-pink"
                      iconColor="new-york-pink"
                      iconType="trash"
                      onConfirmClick={() => dispatch(handleDeleteStoredPaymentMethod(id))}
                      text={t('modal.deleteItem.remove')}
                      textColor="new-york-pink"
                    />
                  </div>
                </InfoRow>
              ))}
            </InfoPanel>
          )}
        </>
      )}
    </div>
  )
}
