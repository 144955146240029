import { Icon } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'
import { generatePath } from 'react-router'

import { ORDER_TAB_NAME } from '../../common/constants'
import { ROUTES } from '../../common/routes'
import defaultImage from '../../images/no-image.svg'
import LinkWithSearch from '../common/LinkWithSearch'
import ServiceAvailability from '../ServiceAvailability/ServiceAvailability'
import s from './SalePointTeaser.module.scss'

export default function SalePointTeaser({ closed, hotelCode, salePointCode, title, description, image, unavailable }) {
  const url = generatePath(ROUTES.SERVICE_PAGE, { hotelCode, salePointCode, tabName: ORDER_TAB_NAME })

  return (
    <div className={s['sale-point-teaser']}>
      <LinkWithSearch to={url}>
        <img alt={title} className={s['sale-point-image']} src={image || defaultImage} />
        <div className={s.panel}>
          <div className={s.details}>
            <p className={s.name}>{title}</p>
            <ServiceAvailability closed={closed} unavailable={unavailable} />
            <p className={s.description} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <Icon className={s.arrow} type="chevron-right-in-circle-fill" />
        </div>
      </LinkWithSearch>
    </div>
  )
}

SalePointTeaser.propTypes = {
  closed: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  hotelCode: PropTypes.string.isRequired,
  image: PropTypes.string,
  salePointCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  unavailable: PropTypes.bool.isRequired
}
