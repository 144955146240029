import { Icon } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React from 'react'

import s from './StepType.module.scss'

export default function StepType({ type }) {
  return (
    <div className={s['step-type']}>
      <Icon
        color={type === 'todo' ? 'default' : 'buoy'}
        size="xxl"
        type={type === 'todo' ? 'arrow-right-in-circle-fill' : 'tick-in-circle-default'}
      />
    </div>
  )
}

StepType.propTypes = {
  type: PropTypes.oneOf(['done', 'todo'])
}
