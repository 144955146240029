import { mediaQueries } from '@tallink/components-lib'
import * as PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useMedia } from 'react-use'
import _ from 'underscore'

import { receivedSalePoints } from '../../actions/actions'
import MirdicClient from '../../clients/MirdicClient'
import { SALE_POINT_UPDATE_INTERVAL, getFacilityTitle } from '../../common/constants'
import AppPromotion from '../../components/AppPromotion/AppPromotion'
import Layout from '../../components/layout/Layout'
import PageHeaderDescription from '../../components/PageHeaderDescription/PageHeaderDescription'
import SalePointTeaser from '../../components/SalePointTeaser/SalePointTeaser'
import useBookingPageInit from '../../hooks/useBookingPageInit'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import useScrollToTop from '../../hooks/useScrollToTop'
import { getBackgroundImageUrl, getSalePoints } from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './ServicesPage.module.scss'

const RANDOM_SECONDS = _.random(0, 59) * 1000

export default function ServicesPage() {
  const { loading, salePointsLoadedOnInit } = useBookingPageInit()

  return loading ? <LoadingPage /> : <ServicesPageLoaded salePointsLoadedOnInit={salePointsLoadedOnInit} />
}

function ServicesPageLoaded({ salePointsLoadedOnInit }) {
  useScrollToTop()
  useNativeBackButton()
  const { t } = useTranslation()
  const smUp = useMedia(mediaQueries.smUp)
  const salePoints = useSelector(getSalePoints)
  const backgroundImageUrl = useSelector(getBackgroundImageUrl)
  const { hotelCode } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const updateSalePoints = () =>
      MirdicClient.getHotelSalePoints(hotelCode).then(salePoint => {
        dispatch(receivedSalePoints(salePoint))
      })

    if (!salePointsLoadedOnInit) updateSalePoints()

    const interval = setInterval(updateSalePoints, SALE_POINT_UPDATE_INTERVAL + RANDOM_SECONDS)

    return () => clearInterval(interval)
  }, [dispatch, hotelCode, salePointsLoadedOnInit])

  return (
    <Layout
      backgroundImageUrl={backgroundImageUrl}
      headerProps={{ title: getFacilityTitle(hotelCode) }}
      navigationVisible
      pageTitle={t('page.services.title')}
    >
      <PageHeaderDescription description={t('services.header.description')} title={t('page.services.title')} />
      {!smUp && <AppPromotion />}
      <div className={s.shadow}>
        <div className={s.row}>
          {salePoints
            .filter(salePoint => salePoint.isVisible)
            .map(
              ({
                name: { title, imageUrl, shortDescription },
                minutesBeforeClosing,
                salePointId,
                salePointCode,
                isAvailable
              }) => (
                <SalePointTeaser
                  closed={minutesBeforeClosing.general === 0}
                  description={shortDescription}
                  hotelCode={hotelCode}
                  image={imageUrl}
                  key={salePointId}
                  salePointCode={salePointCode}
                  title={title}
                  unavailable={!isAvailable}
                />
              )
            )}
        </div>
      </div>
    </Layout>
  )
}

ServicesPageLoaded.propTypes = {
  salePointsLoadedOnInit: PropTypes.bool.isRequired
}
