import { Button, Overlay, Spacer } from '@tallink/components-lib'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import s from './Modal.module.scss'

export default function Modal({ cancelButtonText, asView, children, confirmButtonText, onCancel, onConfirm }) {
  return (
    <Overlay>
      <div aria-modal="true" className={classNames(s.modal, { [s.scroll]: asView })} role="dialog">
        <div className={classNames(s.content, { [s['without-gap']]: asView })}>
          <div className={s.body}>{children}</div>
          {confirmButtonText && cancelButtonText && (
            <div className={s.footer}>
              <Button onClick={onConfirm} type="danger">
                {confirmButtonText}
              </Button>
              <Spacer size="m" />
              <Button onClick={onCancel} type="text">
                {cancelButtonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Overlay>
  )
}

Modal.propTypes = {
  asView: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}
